import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { useProject } from "contexts/project-context";
import { BasicFilter, FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { CustomSetting } from "../custom-settings";
import { CUSTOM_SETTINGS_FILTERS } from "../custom-settings.filters";
import { CustomSettingsField } from "../custom-settings.form";
import { CUSTOM_SETTINGS_INDEX } from "../custom-settings.gql";

export default function CustomSettingsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<CustomSettingsField | null>(
    CustomSettingsField.CreatedAt
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.DESC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();

  const { activeProject } = useProject();

  const handleSorting = (column: CustomSettingsField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(CUSTOM_SETTINGS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(activeProject && {
              projectId: {
                eq: activeProject?.id,
              },
            }),
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  const customSettings: { totalCount: number; nodes: CustomSetting[] } =
    data?.customSettings;

  useEffect(() => {
    if (data?.customSettings) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.customSettings) setIsFetching(false);
    if (customSettings?.totalCount >= 0)
      setTotalCount(customSettings?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CustomSettingsField.Name)}
            sortDirection={
              sortBy === CustomSettingsField.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        id: CustomSettingsField.Name,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue
            dataType={DataType.CustomSettings}
            data={row.original.id}
            deletedAt={row.original.deletedAt}
          >
            {row.original.name}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Projekt",
        accessor: CustomSettingsField.Project,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue
            dataType={DataType.Projects}
            data={row.original?.project?.id}
            $style={{ fontSize: "12px" }}
          >
            {row.original?.project?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Wersja językowa",
        accessor: CustomSettingsField.Language,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue
            dataType={DataType.Language}
            data={row.original?.project?.id}
            $style={{ fontSize: "12px" }}
          >
            {row.original?.language}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CustomSettingsField.SystemName)}
            sortDirection={
              sortBy === CustomSettingsField.SystemName ? sortDirection : null
            }
          >
            Nazwa systemowa
          </SortingTableHeader>
        ),
        accessor: CustomSettingsField.SystemName,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue>{row.original.systemName}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CustomSettingsField.Value)}
            sortDirection={
              sortBy === CustomSettingsField.Value ? sortDirection : null
            }
          >
            Wartość
          </SortingTableHeader>
        ),
        accessor: CustomSettingsField.Value,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue>{row.original.value}</FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            <SortingTableHeader
              onClick={() => handleSorting(CustomSettingsField.CreatedAt)}
              sortDirection={
                sortBy === CustomSettingsField.CreatedAt ? sortDirection : null
              }
            >
              Utworzenie
            </SortingTableHeader>
          </Block>
        ),
        accessor: CustomSettingsField.CreatedAt,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType={DataType.Date}>
              {row.original.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Utworzono przez",
        accessor: CustomSettingsField.CreatedBy,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.createdBy?.id}
          >
            {row.original?.createdBy
              ? renderUserLabel(row.original?.createdBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            <SortingTableHeader
              onClick={() => handleSorting(CustomSettingsField.UpdatedAt)}
              sortDirection={
                sortBy === CustomSettingsField.UpdatedAt ? sortDirection : null
              }
            >
              Ostatnia aktualizacja
            </SortingTableHeader>
          </Block>
        ),
        accessor: CustomSettingsField.UpdatedAt,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType={DataType.Date}>
              {row.original.updatedAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        accessor: CustomSettingsField.UpdatedBy,
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.updatedBy?.id}
          >
            {row.original?.updatedBy
              ? renderUserLabel(row.original?.updatedBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<CustomSetting> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() =>
                history.push(`/custom-settings/${row.original.id}`)
              }
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.customSetting.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Inne treści"
        recordsNum={customSettings?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj pozycję",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.customSetting.create),
            onClick: () => history.push("/custom-settings/create"),
          },
        ]}
      />
      <Filters
        filters={CUSTOM_SETTINGS_FILTERS.filter((filter: BasicFilter) =>
          activeProject ? filter.id !== CustomSettingsField.Project : filter
        )}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<CustomSetting>
              columns={columns}
              data={customSettings?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
