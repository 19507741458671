import { OutputData } from "@editorjs/editorjs";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Category } from "containers/Categories/categories";
import { Project } from "containers/Projects/projects";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";
import { Tag } from "containers/Tags/tags";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type PostsFormInputs = {
  language: SelectValue;
  project: Project[];
  name: string;
  slug: string;
  content: OutputData[];
  lead: string;
  isVisible: boolean;
  tags: Tag[];
  categories: Category[];
  image: File;
  publishedAt: Date;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
};

export enum PostsField {
  Language = "language",
  Project = "project",
  Name = "name",
  Slug = "slug",
  Lead = "lead",
  Content = "content",
  PublishedAt = "publishedAt",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  IsVisible = "isVisible",
  Categories = "categories",
  Tags = "tags",
  ReadTime = "readTime",
  Image = "image",
  ImageUrl = "imageUrl",
  SeoTagset = "seoTagset",
}

export const POSTS_FIELDS: FieldsGroup[] = [
  {
    id: PostsField.Project,
    label: "",
    fields: [
      {
        id: PostsField.Project,
        label: "Projekt",
        span: 6,
        type: FieldType.ProjectsSelect,
        dataType: DataType.Projects,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: PostsField.Language,
        label: "Wersja językowa",
        span: 6,
        type: FieldType.ProjectLanguagesSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Lista zawiera języki przypisane do wybranego projektu",
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: PostsField.Name,
        label: "Tytuł",
        type: FieldType.BigInput,
        autocomplete: "title",
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: PostsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: PostsField.PublishedAt,
        label: "Data publikacji",
        span: 6,
        type: FieldType.DatePicker,
        dataType: DataType.Date,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: PostsField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: PostsField.ReadTime,
        label: "Czas czytania",
        type: FieldType.NumberInput,
        dataType: DataType.Minutes,
        endEnhancer: "min",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: PostsField.Lead,
        label: "Lead",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: PostsField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "image",
    label: "Media",
    fields: [
      {
        id: PostsField.ImageUrl,
        label: "Grafika",
        type: FieldType.Image,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: PostsField.Image,
        label: "Grafika",
        type: FieldType.ImageCropper,
        span: 12,
        imageRequirements: { aspect: 1.5 },
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.MetaDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 200 znaków ze spacjami",
        maxLength: 200,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [PostsField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "taging",
    label: "Przynależność",
    fields: [
      {
        id: PostsField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.CategoriesSelect,
        dataType: DataType.Categories,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: PostsField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Categories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: PostsField.Tags,
        label: "Tagi",
        span: 12,
        type: FieldType.TagsSelect,
        dataType: DataType.Tags,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: PostsField.Tags,
        label: "Tagi",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Tags,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: PostsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: PostsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: PostsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: PostsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
