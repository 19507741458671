import { BasicFilter, FilterType } from "../../filters.d";
import { FilesField } from "./files.form";

export const FILES_FILTERS: BasicFilter[] = [
  {
    id: FilesField.Project,
    label: "Projekt",
    type: FilterType.Project,
  },
  {
    id: FilesField.Language,
    label: "Wersja językowa",
    type: FilterType.Language,
  },
  {
    id: FilesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: FilesField.Filename,
    label: "Nazwa pliku",
    type: FilterType.Text,
  },
  {
    id: FilesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: FilesField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: FilesField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: FilesField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: FilesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
