import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Button } from "components/button";
import { DataType, FormattedValue } from "components/formatted-value";
import { Role } from "containers/Roles/roles";
import { User } from "containers/Users/users";
import { UsersField } from "containers/Users/users.form";
import React from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-table";
import { Eye } from "tabler-icons-react";

import Table from "./table";

type ProjectsUsersTableProps = {
  users: User[];
  loading?: boolean;
};

export default function ProjectUsersTable({
  users,
  loading,
}: ProjectsUsersTableProps): React.ReactElement {
  const [css] = useStyletron();
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "Imię i nazwisko",
        id: "fullName",
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <FormattedValue data={row.original.id} dataType="model:users">
              {`${row.original.firstName} ${row.original.lastName}`}
            </FormattedValue>
          </>
        ),
      },
      {
        Header: "Adres e-mail",
        accessor: UsersField.Email,
        Cell: ({ row }: { row: Row<User> }) => (
          <FormattedValue
            dataType={DataType.Email}
            $style={{ fontSize: "12px" }}
          >
            {row.original.email}
          </FormattedValue>
        ),
      },
      {
        Header: "Status",
        id: UsersField.Status,
        Cell: ({ row }: { row: Row<User> }) => {
          const handleUserStatus = () => {
            if (
              row?.original?.createdAt &&
              row?.original?.invitedAt &&
              !row?.original?.activatedAt &&
              !row?.original?.blockedAt &&
              !row?.original.deletedAt
            )
              return "Wysłano zaproszenie";
            else if (
              row?.original?.createdAt &&
              row?.original?.activatedAt &&
              !row?.original?.blockedAt &&
              !row?.original.deletedAt
            )
              return "Aktywny";
            else if (row?.original?.blockedAt && !row?.original?.deletedAt)
              return "Zablokowany";
          };
          return (
            <FormattedValue dataType={DataType.UserStatus}>
              {handleUserStatus()}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Role",
        id: UsersField.Roles,
        Cell: ({ row }: { row: Row<User> }) => {
          return (
            <Block
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              {row.original?.roles?.length ? (
                row.original?.roles?.map((role: Role) => (
                  <FormattedValue
                    key={`role-${role?.id}`}
                    dataType={DataType.Roles}
                    data={role.id}
                  >
                    {role?.name}
                  </FormattedValue>
                ))
              ) : (
                <FormattedValue />
              )}
            </Block>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<User> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/users/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Table<User>
      columns={columns}
      data={users}
      isLoading={loading}
      stickLastColumn
      compact
    />
  );
}
