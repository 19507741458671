/**
 * App Constants
 */

export const AUTH_TOKEN = "auth-token";
export const NOT_FOUND_ERROR = "NOT_FOUND_ERROR";
export const INPUT_VALIDATION_ERROR = "INPUT_VALIDATION_ERROR";
export const APP_NAME = "Global CMS";

export const SUPPORT_ADMIN_NAME = "Jan Kowalski";
export const SUPPORT_MAIL = "admin@globalcms.pl";

export const PAGE_SIZE = 25;

export enum ReactAppSystemVersion {
  TEST = "TEST",
  PRODUCTION = "PRODUCTION",
  TRAINING = "TRAINING",
}

export enum FormType {
  Update = "update",
  Create = "create",
}

export enum Typename {
  Article = "Article",
  Category = "Category",
  CustomSetting = "CustomSetting",
  Dictionary = "Dictionary",
  DictionaryValue = "DictionaryValue",
  Image = "Image",
  File = "File",
  Post = "Post",
  Project = "Project",
  Role = "Role",
  SeoTagset = "SeoTagset",
  Slide = "Slide",
  StaticPage = "StaticPage",
  Tag = "Tag",
  User = "User",
}

export const ACTIVITY_LOG_ACTIVITIES = [
  { id: "updated", label: "Aktualizacja" },
  { id: "exportedUsers", label: "Eksport użytkowników" },
  { id: "login", label: "Logowanie" },
  { id: "failedLogin", label: "Nieudane logowanie" },
  { id: "unblocked", label: "Odblokowanie" },
  { id: "revealed", label: "Odczytanie" },
  { id: "forgotPassword", label: "Prośba o reset hasła" },
  { id: "moved", label: "Przeniesienie" },
  { id: "resetPassword", label: "Reset hasła" },
  { id: "hidden", label: "Ukrycie" },
  { id: "setInitialPassword", label: "Ustawienie hasła" },
  { id: "deleted", label: "Usunięcie" },
  { id: "created", label: "Utworzenie" },
  { id: "logout", label: "Wylogowanie" },
  { id: "blocked", label: "Zablokowanie" },
  { id: "changedPassword", label: "Zmiana hasła" },
];

export const ACTIVITY_LOGS_SUBJECTS = [
  { id: "Post", label: "Aktualność" },
  { id: "Article", label: "Artykuł" },
  { id: "Image", label: "Grafika" },
  { id: "Category", label: "Kategoria" },
  { id: "Project", label: "Projekt" },
  { id: "Role", label: "Rola" },
  { id: "Slide", label: "Slajd" },
  { id: "Dictionary", label: "Słownik" },
  { id: "StaticPage", label: "Strona informacyjna" },
  { id: "Tag", label: "Tag" },
  { id: "SeoTagset", label: "Tag SEO" },
  { id: "User", label: "Użytkownik" },
  { id: "DictionaryValue", label: "Wartość słownika" },
];

export const SEO_TAGSETS_SUBJECTS = [
  { id: "Article", label: "Artykuł" },
  { id: "Post", label: "Aktualność" },
  { id: "Category", label: "Kategoria" },
  { id: "Project", label: "Projekt" },
  { id: "StaticPage", label: "Strona informacyjna" },
];

export enum TestEmails {
  AllPermissions = "boyle@codeshine.com",
  NoPermissions = "riemann@codeshine.com",
  PermissionsToShow = "euclid@codeshine.com",
}

export const ALL_PERMISSIONS_USER = "Robert Boyle";

export const TEST_PASSWORD = "password";

export enum Difficulty {
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
}

export const DIFFICULTY_LEVELS = [
  { id: Difficulty.Easy, label: "Łatwy" },
  { id: Difficulty.Medium, label: "Średnio-zaaw." },
  { id: Difficulty.Hard, label: "Wymagający" },
];

export enum DictionarySystemName {
  Languages = "languages",
  RobotsSeoTagsets = "robotsSeoTagsets",
  SocialMedia = "socialMedia",
}
