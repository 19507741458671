import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ButtonProps } from "baseui/button";
import { FileUploader, FileUploaderProps } from "baseui/file-uploader";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { Button } from "components/button";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Trash } from "tabler-icons-react";

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return "0 bajtów";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bajtów", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export enum FilesPickerType {
  Default = "default",
  SingleFile = "single_file",
}

type Props = FileUploaderProps & {
  filesPickerType?: FilesPickerType;
  error?: boolean;
  loading?: boolean;
};

export default function FilesPicker({
  error,
  filesPickerType,
  loading,
  name,
  ...props
}: Props): React.ReactElement {
  const [, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <FileUploader
      {...(filesPickerType === FilesPickerType.SingleFile && {
        multiple: false,
      })}
      name={name}
      overrides={{
        ContentMessage: () => (
          <LabelSmall marginBottom="scale600" color="inputPlaceholder">
            {filesPickerType === FilesPickerType.SingleFile
              ? "Przenieś i upuść plik tutaj"
              : "Przenieś i upuść pliki tutaj"}
          </LabelSmall>
        ),
        ButtonComponent: {
          props: {
            overrides: {
              BaseButton: {
                component: (props: ButtonProps) => (
                  <Block display="flex">
                    <Button {...props} disabled={loading}>
                      {filesPickerType === FilesPickerType.SingleFile
                        ? "Wybierz plik z dysku"
                        : "Wybierz pliki z dysku"}
                    </Button>
                  </Block>
                ),
              },
            },
          },
        },
        FileDragAndDrop: {
          style: {
            backgroundColor: error
              ? theme.colors.inputFillError
              : theme.colors.inputFill,
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            borderTopColor: error
              ? theme.colors.inputBorderError
              : theme.colors.inputBorder,
            borderRightColor: error
              ? theme.colors.inputBorderError
              : theme.colors.inputBorder,
            borderBottomColor: error
              ? theme.colors.inputBorderError
              : theme.colors.inputBorder,
            borderLeftColor: error
              ? theme.colors.inputBorderError
              : theme.colors.inputBorder,
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
          },
        },
      }}
      onDropRejected={(rejectedFiles: File[]) =>
        enqueueSnackbar({
          message:
            filesPickerType === FilesPickerType.Default
              ? "Następujące pliki nie spełniają wymagań: " +
                rejectedFiles.map((i) => i.name + "; ")
              : "Plik nie spełnia wymagań",
          variant: "error",
        })
      }
      {...props}
    />
  );
}

export function ControlledFilesPicker({
  control,
  name,
  rules,
  loading,
  filesPickerType,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <div id={name}>
            {value && (
              <Block
                display="flex"
                flexDirection="column"
                marginBottom="scale200"
              >
                {value.map((file: File, index: number) => {
                  return (
                    <Block
                      key={index}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      paddingTop="scale100"
                      paddingBottom="scale100"
                    >
                      <Trash
                        size={18}
                        className={css({
                          marginRight: theme.sizing.scale200,
                          ...(loading
                            ? {
                                color: theme.colors.mono300,
                              }
                            : {
                                ":hover": {
                                  cursor: "pointer",
                                  color: theme.colors.negative500,
                                },
                                color: theme.colors.negative,
                              }),
                        })}
                        {...(!loading && {
                          onClick: () =>
                            onChange(value.filter((i: File) => i !== file)),
                        })}
                      />
                      <LabelXSmall $style={{ fontWeight: 400 }}>
                        {file.name}
                      </LabelXSmall>

                      <LabelXSmall
                        marginLeft="auto"
                        $style={{ fontWeight: 400 }}
                      >
                        {formatBytes(file.size)}
                      </LabelXSmall>
                    </Block>
                  );
                })}
              </Block>
            )}

            <FilesPicker
              onDropAccepted={(acceptedOrRejected: File[]) => {
                if (value && filesPickerType === FilesPickerType.Default)
                  onChange([...value, ...acceptedOrRejected]);
                else onChange(acceptedOrRejected);
              }}
              onBlur={onBlur}
              loading={loading}
              name={name}
              filesPickerType={filesPickerType}
              {...rest}
            />
          </div>
        );
      }}
    />
  );
}
