const defaultColumns: { [pathname: string]: string[] } = {
  "/posts": [
    "name",
    "project",
    "language",
    "publishedAt",
    "createdAt",
    "isVisible",
  ],
  "/articles": [
    "name",
    "project",
    "language",
    "createdAt",
    "author",
    "isVisible",
  ],
  "/static-pages": ["name", "project", "language", "createdAt", "isVisible"],
  "/categories": [
    "name",
    "project",
    "language",
    "color",
    "createdAt",
    "isVisible",
    "actions",
  ],
  "/custom-settings": ["name", "systemName", "project", "language", "actions"],
  "/slides": ["title", "project", "language", "createdAt", "isVisible"],
  "/images": ["originalName", "project", "language", "createdAt"],
  "/files": ["name", "project", "language", "createdAt", "isVisible"],
  "/projects": ["name", "isActive", "createdAt"],
  "/tags": ["name", "project", "language", "createdAt", "isVisible"],
  "/users": ["fullName", "email", "status", "roles", "projects", "actions"],
  pathname: ["id"],
};

export function getDefaultColumns(pathname: string): string[] | undefined {
  return defaultColumns?.[pathname];
}
