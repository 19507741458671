import { gql, useQuery } from "@apollo/client";
import { DictionaryValue } from "containers/Dictionaries/dictionaries";
import { PROJECT_LANGUAGES } from "containers/Projects/projects.gql";
import { useDictionaries } from "contexts/dictionaries-context";
import { useProject } from "contexts/project-context";
import React, { useEffect } from "react";
import { Controller, UseControllerProps } from "react-hook-form";

import Select, { SelectProps, SelectWithActiveProjectProps } from "./select";

export default function LanguagesSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { data, loading } = useQuery(
    gql`
      query {
        dictionary(systemName: "languages") {
          id
          values {
            id
            value
            name
          }
        }
      }
    `
  );

  const { activeProject } = useProject();

  return (
    <Select
      options={data?.dictionary?.values?.map(
        ({ value, name }: DictionaryValue) => ({
          id: value,
          label: name,
        })
      )}
      getValueLabel={({ option }) => (option?.label as string)?.toLowerCase()}
      getOptionLabel={({ option }) => (option?.label as string)?.toLowerCase()}
      placeholder={"Wybierz"}
      disabled={disabled}
      isLoading={loading}
      {...(activeProject && {
        filterOptions: (options) =>
          options.filter((option) =>
            activeProject.languages.includes(option.id as string)
          ),
      })}
      {...rest}
    />
  );
}

export function ControlledLanguagesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { data, loading } = useQuery(
    gql`
      query {
        dictionary(systemName: "languages") {
          id
          values {
            id
            value
            name
          }
        }
      }
    `
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          multi
          options={data?.dictionary?.values?.map(
            ({ value, name }: DictionaryValue) => ({
              id: value,
              label: name,
            })
          )}
          getValueLabel={({ option }) =>
            (option?.label as string)?.toLowerCase()
          }
          getOptionLabel={({ option }) =>
            (option?.label as string)?.toLowerCase()
          }
          placeholder={"Wybierz"}
          isLoading={loading}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledProjectLanguagesSelect({
  control,
  name,
  rules,
  disabled,
  activeProjectId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectWithActiveProjectProps): React.ReactElement {
  const { findValue } = useDictionaries();

  const { data, loading, refetch } = useQuery(PROJECT_LANGUAGES, {
    variables: {
      id: activeProjectId,
    },
  });

  useEffect(() => {
    refetch();
  }, [activeProjectId]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          onChange={(params) => params && onChange(params.value)}
          options={data?.project?.languages?.map((language: string) => ({
            id: language,
            label: findValue(language),
          }))}
          getValueLabel={({ option }) =>
            (option?.label as string)?.toLowerCase()
          }
          getOptionLabel={({ option }) =>
            (option?.label as string)?.toLowerCase()
          }
          placeholder={"Wybierz"}
          disabled={disabled}
          isLoading={loading}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledMainLanguageSelect({
  control,
  name,
  rules,
  options,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          placeholder="Wybierz"
          onChange={(params) => params && onChange(params.value)}
          getValueLabel={({ option }) =>
            (option?.label as string)?.toLowerCase()
          }
          getOptionLabel={({ option }) =>
            (option?.label as string)?.toLowerCase()
          }
          onBlur={onBlur}
          value={value}
          {...(options && { options })}
          {...rest}
        />
      )}
    />
  );
}
