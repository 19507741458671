import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelXSmall } from "baseui/typography";
import { Button } from "components/button";
import { FilesPicker } from "components/files-picker";
import { FilesPickerType } from "components/files-picker/files-picker";
import { Tooltip } from "components/tooltip";
import { useLoading } from "contexts/loading-context";
import React, {
  Fragment,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
} from "react-image-crop";
import { Crop as CropIcon, DeviceFloppy, Vector } from "tabler-icons-react";

import { canvasPreview } from "./canvas-preview";

type ImageCropperProps = {
  aspect?: number;
  onComplete?: (crop: PixelCrop, percentageCrop: PercentCrop) => void;
  file?: File;
  onChange?: (file?: File) => void;
  name?: string;
  error?: boolean;
  rounded?: boolean;
  allowVectors?: boolean;
};

export default function ImageCropper({
  aspect,
  file,
  onChange,
  name,
  error,
  rounded,
  allowVectors = false,
}: ImageCropperProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const { isLoading } = useLoading();

  const [isEditing, setIsEditing] = useState(!file);

  const [image, setImage] = useState<File | undefined>(file);
  const [imageSrc, setImageSrc] = useState<string>();

  const [isVector, setIsVector] = useState(false);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const imageRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const cropperRef = useRef<ReactCrop>(null);

  useEffect(() => {
    if (file?.type.includes("svg")) {
      setIsVector(true);
    }
  }, [file]);

  useEffect(() => {
    if (image && image?.type?.match("image.*")) {
      const reader = new FileReader();

      reader.addEventListener("load", () =>
        setImageSrc(reader.result?.toString() || "")
      );

      reader.readAsDataURL(image as Blob);
    } else {
      setIsEditing(true);
    }
  }, [image]);

  useEffect(() => {
    if (completedCrop) {
      previewCanvasRef.current?.toBlob((blob) => {
        const file = new File(
          [blob as BlobPart],
          image?.name || "cropped.png",
          {
            type: "image/png",
          }
        );

        onChange && onChange(file);
      }, "image/png");
    }
  }, [isEditing]);

  useEffect(() => {
    if (cropperRef.current && crop) {
      setCompletedCrop(cropperRef.current.makePixelCrop());
    }
  }, [crop]);

  function onImageLoad(event: SyntheticEvent<HTMLImageElement>): void {
    const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect || width / height,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  }

  return (
    <Fragment>
      {!image ? (
        <FilesPicker
          filesPickerType={FilesPickerType.SingleFile}
          accept={
            allowVectors
              ? "image/*"
              : "image/apng, image/avif, image/gif, image/jpeg, image/png, image/webp"
          }
          error={error}
          onDropAccepted={(acceptedOrRejected: File[]) => {
            if (acceptedOrRejected[0]?.type.includes("svg")) {
              onChange && onChange(acceptedOrRejected[0]);
              setIsVector(true);
            } else {
              setIsVector(false);
            }

            setImage(acceptedOrRejected[0]);
          }}
          {...(error && {
            error,
          })}
        />
      ) : (
        <div
          id={name}
          className={css({
            backgroundColor: theme.colors.inputFill,
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            borderTopColor: theme.colors.inputBorder,
            borderRightColor: theme.colors.inputBorder,
            borderBottomColor: theme.colors.inputBorder,
            borderLeftColor: theme.colors.inputBorder,
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            padding: "12px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          })}
        >
          <div
            className={css({
              display: "flex",
              marginBottom: "12px",
              alignItems: "center",
              gap: "6px",
            })}
          >
            <LabelXSmall>{image?.name}</LabelXSmall>

            {isVector && (
              <Tooltip content="Plik jest wektorowy — nie ma możliwości kadrowania.">
                <span>
                  <Vector size={12} color="green" />
                </span>
              </Tooltip>
            )}
          </div>

          <div className={css({ maxWidth: "35vw" })}>
            {isVector ? (
              <div
                className={css({
                  backgroundColor: "#EEE",
                })}
              >
                <img ref={imageRef} src={imageSrc} onLoad={onImageLoad} />
              </div>
            ) : (
              <ReactCrop
                ref={cropperRef}
                disabled={!isEditing}
                crop={crop}
                onChange={(crop) => setCrop(crop)}
                onComplete={(crop) => setCompletedCrop(crop)}
                aspect={aspect}
                circularCrop={rounded}
              >
                <img ref={imageRef} src={imageSrc} onLoad={onImageLoad} />
              </ReactCrop>
            )}
          </div>

          <Block display="flex" marginTop="12px">
            <Button
              kind="secondary"
              onClick={() => {
                setImage(undefined);
                setCrop(undefined);
                setCompletedCrop(undefined);
                onChange && onChange(undefined);
              }}
              disabled={isLoading}
            >
              Usuń grafikę
            </Button>

            {!isVector && (
              <Button
                kind="primary"
                $style={{ marginLeft: "6px" }}
                data-test-id="toggle-cropper-mode"
                onClick={() => {
                  if (
                    completedCrop?.width &&
                    completedCrop?.height &&
                    imageRef.current &&
                    previewCanvasRef.current
                  ) {
                    canvasPreview(
                      imageRef.current,
                      previewCanvasRef.current,
                      completedCrop,
                      1,
                      0
                    );
                  }

                  setIsEditing((isEditing) => !isEditing);
                }}
                type="button"
                disabled={isLoading}
                startEnhancer={
                  isEditing ? (
                    <DeviceFloppy size={18} />
                  ) : (
                    <CropIcon size={20} />
                  )
                }
              >
                {isEditing ? "Zapisz" : "Kadruj"}
              </Button>
            )}
          </Block>
        </div>
      )}

      <canvas
        ref={previewCanvasRef}
        className={css({
          objectFit: "contain",
          width: completedCrop?.width,
          height: completedCrop?.height,
          position: "absolute",
          pointerEvents: "none",
          display: "none",
        })}
      />
    </Fragment>
  );
}

export function ControlledImageCropper({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & ImageCropperProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <ImageCropper
            file={value}
            onChange={onChange}
            name={name}
            {...rest}
          />
        );
      }}
    />
  );
}
