import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Project } from "containers/Projects/projects";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";
import { Category } from "./categories";

export type CategoriesFormInputs = {
  project: Project[];
  language: SelectValue;
  name: string;
  slug: string;
  description: string;
  isVisible: boolean;
  isFeatured: boolean;
  image: File;
  parent: Category[];
  color: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
};

export enum CategoriesField {
  Language = "language",
  Project = "project",
  Name = "name",
  Slug = "slug",
  Description = "description",
  IsVisible = "isVisible",
  IsFeatured = "isFeatured",
  ImageUrl = "imageUrl",
  Image = "image",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Parent = "parent",
  Relations = "relations",
  Color = "color",
  SeoTagset = "seoTagset",
}

export const CATEGORIES_FIELDS: FieldsGroup[] = [
  {
    id: CategoriesField.Project,
    label: "",
    fields: [
      {
        id: CategoriesField.Project,
        label: "Projekt",
        span: 6,
        type: FieldType.ProjectsSelect,
        dataType: DataType.Projects,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: CategoriesField.Language,
        label: "Wersja językowa",
        span: 6,
        type: FieldType.ProjectLanguagesSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Lista zawiera języki przypisane do wybranego projektu",
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: CategoriesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        autocomplete: "title",
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: CategoriesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: CategoriesField.Description,
        label: "Opis",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: CategoriesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: CategoriesField.IsFeatured,
        label: "Wyróżnienie",
        type: FieldType.Switch,
        dataType: DataType.Boolean,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: CategoriesField.Relations,
    label: "Relacje",
    fields: [
      {
        id: CategoriesField.Parent,
        label: "Kategoria nadrzędna",
        span: 12,
        type: FieldType.ParentCategorySelect,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
        caption: "Dostępne są tylko kategorie przypisane do wybranego projektu",
      },
      {
        id: CategoriesField.Relations,
        label: "",
        span: 12,
        type: FieldType.CategoryRelations,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "appearance",
    label: "Wygląd",
    fields: [
      {
        id: CategoriesField.Image,
        label: "Grafika",
        span: 12,
        type: FieldType.ImageCropper,
        imageRequirements: { aspect: 1, allowVectors: true },
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: CategoriesField.ImageUrl,
        label: "Grafika",
        span: 12,
        type: FieldType.Image,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },

      {
        id: CategoriesField.Color,
        label: "Kolor",
        span: 12,
        type: FieldType.ColorPicker,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [CategoriesField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [CategoriesField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [
            CategoriesField.SeoTagset,
            SeoTagsetsField.MetaDescription,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 200 znaków ze spacjami",
        maxLength: 200,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [CategoriesField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [CategoriesField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [CategoriesField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [CategoriesField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: CategoriesField.CreatedAt,
        label: "Utworzenie",
        span: 4,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: CategoriesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 4,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
