import { gql } from "@apollo/client";

export const CUSTOM_SETTINGS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: CustomSettingFilter
    $sorting: [CustomSettingSort!]
  ) {
    customSettings(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        systemName
        value
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        project {
          id
          name
        }
        language
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          email
        }
        deletedAt
      }
    }
  }
`;

export const CUSTOM_SETTINGS_SHOW = gql`
  query CustomSettingShow($id: Int!) {
    customSetting(id: $id) {
      id
      name
      systemName
      value
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      project {
        id
        name
      }
      language
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        email
      }
      deletedAt
    }
  }
`;

export const CUSTOM_SETTINGS_CREATE = gql`
  mutation CustomSettingCreate(
    $customSettingCreateInput: CustomSettingCreateInput!
  ) {
    customSettingCreate(customSettingCreateInput: $customSettingCreateInput) {
      id
    }
  }
`;

export const CUSTOM_SETTINGS_UPDATE = gql`
  mutation CustomSettingUpdate(
    $customSettingUpdateInput: CustomSettingUpdateInput!
  ) {
    customSettingUpdate(customSettingUpdateInput: $customSettingUpdateInput) {
      id
    }
  }
`;

export const CUSTOM_SETTINGS_DELETE = gql`
  mutation CustomSettingDelete(
    $customSettingDeleteInput: CustomSettingDeleteInput!
  ) {
    customSettingDelete(customSettingDeleteInput: $customSettingDeleteInput) {
      id
    }
  }
`;
