import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import {
  ALIGN,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "baseui/radio";
import { Input } from "components/input";
import { PostsSelect, StaticPagesSelect } from "components/select";
import ArticlesSelect from "components/select/articles-select";
import { useLoading } from "contexts/loading-context";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";

export enum SlideVariant {
  Article = "article",
  Custom = "custom",
  Post = "post",
  StaticPage = "staticPage",
}

export type SlideVariantSelectorValue = {
  type?: SlideVariant | string;
  value?: string | number;
  buttonLabel?: string;
};

type SlideVariantSelectorProps = {
  value?: SlideVariantSelectorValue;
  onChange?: (value?: SlideVariantSelectorValue) => void;
  activeProjectId?: number;
};

const SLIDE_VARIANTS = [
  { id: SlideVariant.Post, label: "Aktualność" },
  { id: SlideVariant.Article, label: "Artykuł" },
  { id: SlideVariant.StaticPage, label: "Strona informacyjna" },
  { id: SlideVariant.Custom, label: "Zewnętrzny odnośnik" },
];

export default function SlideVariantSelector({
  value,
  onChange,
  activeProjectId,
  ...rest
}: RadioProps &
  RadioGroupProps &
  SlideVariantSelectorProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const { isLoading } = useLoading();
  const [selectedVariant, setSelectedVariant] = useState<
    SlideVariantSelectorValue | undefined
  >(value);

  useEffect(() => {
    selectedVariant
      ? onChange && onChange(selectedVariant)
      : onChange && onChange(undefined);
  }, [selectedVariant]);

  useEffect(() => {
    setSelectedVariant(value);
  }, [value]);

  const renderVariantInput = (slideVariant?: SlideVariant) => {
    switch (slideVariant) {
      case SlideVariant.Post:
        return (
          <PostsSelect
            id="posts"
            disabled={isLoading}
            placeholder="Wybierz aktualność"
            activeProjectId={activeProjectId}
            onChange={(params) => {
              return setSelectedVariant((selectedVariant) => ({
                type: selectedVariant?.type,
                value: params.option?.id,
              }));
            }}
            {...(selectedVariant?.value && {
              value: [{ id: selectedVariant?.value }],
            })}
            $controlContainerStyle={{ backgroundColor: "white" }}
          />
        );

      case SlideVariant.Article:
        return (
          <ArticlesSelect
            id="articles"
            disabled={isLoading}
            placeholder="Wybierz artykuł"
            activeProjectId={activeProjectId}
            onChange={(params) => {
              return setSelectedVariant((selectedVariant) => ({
                type: selectedVariant?.type,
                value: params.option?.id,
              }));
            }}
            {...(selectedVariant?.value && {
              value: [{ id: selectedVariant?.value }],
            })}
            $controlContainerStyle={{ backgroundColor: "white" }}
          />
        );

      case SlideVariant.StaticPage:
        return (
          <StaticPagesSelect
            id="staticPages"
            disabled={isLoading}
            placeholder="Wybierz stronę informacyjną"
            activeProjectId={activeProjectId}
            onChange={(params) => {
              return setSelectedVariant((selectedVariant) => ({
                type: selectedVariant?.type,
                value: params.option?.id,
              }));
            }}
            {...(selectedVariant?.value && {
              value: [{ id: selectedVariant?.value }],
            })}
            $controlContainerStyle={{ backgroundColor: "white" }}
          />
        );

      case SlideVariant.Custom:
        return (
          <div
            className={css({
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            })}
          >
            <Input
              id="url"
              autoComplete="website"
              disabled={isLoading}
              placeholder="Wpisz adres strony WWW"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSelectedVariant((selectedVariant) => ({
                  type: selectedVariant?.type,
                  value: e.currentTarget?.value,
                }))
              }
              value={selectedVariant?.value}
              $style={{ backgroundColor: "white" }}
            />

            <Input
              id="buttonLabel"
              disabled={isLoading}
              autoComplete="none"
              placeholder="Wpisz etykietę przycisku"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSelectedVariant((selectedVariant) => ({
                  ...selectedVariant,
                  buttonLabel: e.currentTarget?.value,
                }))
              }
              value={selectedVariant?.buttonLabel}
              $style={{ backgroundColor: "white" }}
            />
          </div>
        );
    }
  };

  return (
    <Block
      display="flex"
      width="100%"
      padding="12px"
      backgroundColor="inputFill"
      flexDirection="column"
      overrides={{
        Block: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            borderLeftWidth: "2px",
            borderLeftStyle: "solid",
            borderLeftColor: theme.colors.inputBorder,
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.colors.inputBorder,
            borderRightWidth: "2px",
            borderRightStyle: "solid",
            borderRightColor: theme.colors.inputBorder,
            borderTopWidth: "2px",
            borderTopStyle: "solid",
            borderTopColor: theme.colors.inputBorder,
            gap: "2px",
          },
        },
      }}
    >
      <Block>
        <RadioGroup
          value={selectedVariant?.type}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSelectedVariant({
              type: e.target.value as SlideVariant,
              value: "",
            })
          }
          name="number"
          align={ALIGN.horizontal}
          {...rest}
        >
          {SLIDE_VARIANTS.map((button) => (
            <Radio
              value={button.id}
              key={button.id}
              overrides={{
                RadioMarkInner: {
                  style: ({ $checked }) => ({
                    width: $checked ? "6px" : "12px",
                    height: $checked ? "6px" : "12px",
                  }),
                },
                RadioMarkOuter: {
                  style: {
                    width: "16px",
                    height: "16px",
                  },
                },
                Label: {
                  style: {
                    fontSize: "13px",
                    fontWeight: 400,
                    paddingLeft: "6px",
                  },
                },
                Root: {
                  style: {
                    marginRight: "16px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  },
                },
              }}
            >
              {button.label}
            </Radio>
          ))}
        </RadioGroup>
      </Block>

      <Block
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="12px"
      >
        {selectedVariant &&
          renderVariantInput(selectedVariant?.type as SlideVariant)}
      </Block>
    </Block>
  );
}

export function ControlledSlideVariantSelector({
  control,
  name,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SlideVariantSelectorProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          return !value?.value
            ? `Nie ${
                value?.type === SlideVariant.Custom ? "wpisano" : "wybrano"
              } ${
                value?.type === SlideVariant.Article
                  ? "artykułu"
                  : value?.type === SlideVariant.Post
                  ? "aktualności"
                  : value?.type === SlideVariant.StaticPage
                  ? "strony informacyjnej"
                  : "adresu strony WWW lub etykiety przycisku"
              }`
            : true;
        },
      }}
      render={({ field: { onChange, value, name } }) => {
        return (
          <SlideVariantSelector
            onChange={onChange}
            name={name}
            value={value}
            {...rest}
          />
        );
      }}
    />
  );
}
