import { useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Post } from "containers/Posts/posts";
import { POSTS_SELECT } from "containers/Posts/posts.gql";
import { useAuth } from "contexts/auth-context";
import React from "react";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { SelectWithActiveProjectProps } from "./select";

export default function PostsSelect({
  disabled,
  activeProjectId,
  ...rest
}: SelectWithActiveProjectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const { data, loading } = useQuery(POSTS_SELECT, {
    variables: {
      ...(activeProjectId && {
        filter: {
          projectId: {
            eq: activeProjectId,
          },
        },
      }),
    },
  });

  const hasPermission = checkPermission(PERMISSIONS.post.read);

  return (
    <Select
      options={data?.posts?.nodes?.map(({ id, name }: Post) => ({
        id,
        label: name,
      }))}
      getValueLabel={({ option }) => `${option?.label}`}
      getOptionLabel={({ option }) => `${option?.label}`}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: "5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania aktualności
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      isLoading={loading}
      {...rest}
    />
  );
}
