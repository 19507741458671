import { gql } from "@apollo/client";

export const IMAGES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: ImageFilter
    $sorting: [ImageSort!]
  ) {
    images(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        url
        originalName
        mimetype
        createdAt
        alt
        project {
          id
          name
        }
        language
        createdBy {
          id
          firstName
          lastName
          email
        }
        updatedAt
        deletedAt
      }
    }
  }
`;

export const IMAGES_SHOW = gql`
  query ImageShow($id: Int!) {
    image(id: $id) {
      id
      url
      originalName
      mimetype
      alt
      project {
        id
        name
      }
      language
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      updatedAt
      deletedAt
    }
  }
`;

export const IMAGES_UPLOAD = gql`
  mutation ImageUpload($imageUploadInput: ImageUploadInput!) {
    imageUpload(imageUploadInput: $imageUploadInput) {
      id
    }
  }
`;

export const IMAGES_UPDATE = gql`
  mutation ImageUpdate($imageUpdateInput: ImageUpdateInput!) {
    imageUpdate(imageUpdateInput: $imageUpdateInput) {
      id
    }
  }
`;

export const IMAGES_DELETE = gql`
  mutation ImageDelete($imageDeleteInput: ImageDeleteInput!) {
    imageDelete(imageDeleteInput: $imageDeleteInput) {
      id
    }
  }
`;
