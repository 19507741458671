import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { useProject } from "contexts/project-context";
import { BasicFilter, FiltersState, SingleFilter } from "filters";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import {
  ChevronDown,
  ChevronDownLeft,
  ChevronRight,
  Eye,
  Plus,
} from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import {
  getColumnDataAlign,
  getColumnWidth,
} from "utils/interface/column-sizes";
import { getDefaultColumns } from "utils/interface/default-columns";
import { getPreferencedColumns } from "utils/interface/preferences";
import { PERMISSIONS } from "utils/permissions";

import { Category } from "../categories";
import { CATEGORIES_FILTERS } from "../categories.filters";
import { CategoriesField } from "../categories.form";
import { CATEGORIES_INDEX, CATEGORIES_UPDATE } from "../categories.gql";

export default function CategoriesIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<CategoriesField | null>(
    CategoriesField.Name
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission, interfacePreferences } = useAuth();

  const { pathname } = useLocation();

  const [filters, setFilters] = useState<FiltersState>();
  const shouldHaveTreeStructure =
    !filters?.length ||
    filters?.some((filter: SingleFilter) => filter.state.like === "");

  const { activeProject } = useProject();

  const handleSorting = (column: CategoriesField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(CATEGORIES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(activeProject && {
              projectId: {
                eq: activeProject?.id,
              },
            }),
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });
  const categories: { totalCount: number; nodes: Category[] } =
    data?.categories;

  useEffect(() => {
    if (categories) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.categories) setIsFetching(false);
    if (categories?.totalCount >= 0) setTotalCount(categories?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [updateCategory] = useMutation(CATEGORIES_UPDATE);

  const onSubmit = async (
    category: Category,
    field: CategoriesField.IsVisible | CategoriesField.IsFeatured
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await updateCategory({
        variables: {
          categoryUpdateInput: {
            id: category.id,
            ...(field === CategoriesField.IsVisible
              ? { isVisible: !category.isVisible }
              : { isFeatured: !category.isFeatured }),
          },
        },
      });
      enqueueSnackbar({
        message:
          field === CategoriesField.IsVisible
            ? `Kategoria "${category.name}" jest ${
                category.isVisible ? "niewidoczna" : "widoczna"
              }`
            : `Kategoria "${category.name}" jest ${
                category.isFeatured ? "niewyróżniona" : "wyróżniona"
              }`,
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: Row<Category> }) =>
          shouldHaveTreeStructure ? (
            <Block display="flex" justifyContent="flex-start">
              {row.canExpand ? (
                <div {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? (
                    <ChevronDown
                      size={16}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                        marginLeft: `${(row.depth - 1) * 8}px`,
                      })}
                    />
                  ) : (
                    <ChevronRight
                      size={16}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                        marginLeft: `${(row.depth - 1) * 8}px`,
                      })}
                    />
                  )}
                </div>
              ) : (
                row.depth > 0 && (
                  <div>
                    <ChevronDownLeft
                      size={16}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                        marginLeft: `${(row.depth - 1) * 8}px`,
                      })}
                    />
                  </div>
                )
              )}
              <StyledLink
                onClick={(event: MouseEvent) => {
                  event.preventDefault();
                  history.push(`/categories/${row.original.id}`);
                }}
                href={`/categories/${row.original.id}`}
                style={{ cursor: "pointer" }}
              >
                {row?.original?.name}
              </StyledLink>
            </Block>
          ) : (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/categories/${row.original.id}`);
              }}
              href={`/categories/${row.original.id}`}
              style={{ cursor: "pointer" }}
            >
              {row?.original?.name}
            </StyledLink>
          ),
      },
      {
        Header: "Projekt",
        accessor: CategoriesField.Project,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue
            dataType={DataType.Projects}
            data={row.original?.project?.id}
            $style={{ fontSize: "12px" }}
          >
            {row.original?.project?.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.Language)}
            sortDirection={
              sortBy === CategoriesField.Language ? sortDirection : null
            }
          >
            Wersja językowa
          </SortingTableHeader>
        ),
        accessor: CategoriesField.Language,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue
            dataType={DataType.Language}
            data={row.original?.project?.id}
            $style={{ fontSize: "12px" }}
          >
            {row.original?.language}
          </FormattedValue>
        ),
      },
      {
        Header: "Kolor",
        id: CategoriesField.Color,
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              width: "100%",
              height: "28px",
              backgroundColor: row.original.color,
              borderRadius: "5px",
              marginRight: "8px",
            })}
          />
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.Slug)}
            sortDirection={
              sortBy === CategoriesField.Slug ? sortDirection : null
            }
          >
            Slug
          </SortingTableHeader>
        ),
        accessor: CategoriesField.Slug,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.slug}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.CreatedAt)}
            sortDirection={
              sortBy === CategoriesField.CreatedAt ? sortDirection : null
            }
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        accessor: CategoriesField.CreatedAt,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.UpdatedAt)}
            sortDirection={
              sortBy === CategoriesField.UpdatedAt ? sortDirection : null
            }
          >
            Ostatnia aktualizacja
          </SortingTableHeader>
        ),
        accessor: CategoriesField.UpdatedAt,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.Description)}
            sortDirection={
              sortBy === CategoriesField.Description ? sortDirection : null
            }
          >
            Opis
          </SortingTableHeader>
        ),
        id: CategoriesField.Description,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue>{row.original?.description}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.IsVisible)}
            sortDirection={
              sortBy === CategoriesField.IsVisible ? sortDirection : null
            }
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: CategoriesField.IsVisible,
        Cell: ({ row }: { row: Row<Category> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isVisible ? "niewidoczna" : "widoczna"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, CategoriesField.IsVisible)}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.isVisible}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.IsFeatured)}
            sortDirection={
              sortBy === CategoriesField.IsFeatured ? sortDirection : null
            }
          >
            Wyróżnienie
          </SortingTableHeader>
        ),
        accessor: CategoriesField.IsFeatured,
        Cell: ({ row }: { row: Row<Category> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isFeatured ? "niewyróżniona" : "wyróżniona"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, CategoriesField.IsFeatured)}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.Boolean}>
                {row.original.isFeatured}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Category> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/categories/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.category.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Kategorie"
        recordsNum={categories?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj kategorię",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.category.create),
            onClick: () => history.push("/categories/create"),
          },
        ]}
      />
      <Filters
        filters={CATEGORIES_FILTERS.filter((filter: BasicFilter) =>
          activeProject ? filter.id !== CategoriesField.Project : filter
        )}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<Category>
              expandedNumber={100}
              stickLastColumn
              isLoading={isFetching || isPartialFetching || loading}
              columns={columns}
              data={categories?.nodes}
              {...(shouldHaveTreeStructure && {
                $rows: (rows, prepareRow) => {
                  const visibleColumns =
                    getPreferencedColumns(interfacePreferences, pathname) ||
                    getDefaultColumns(pathname);

                  const nestChildrenUnits = (
                    category: Category,
                    depth: number
                  ): Row<Category>[] => {
                    return rows.filter(
                      (row) =>
                        (row.original as Category).parent?.id === category?.id
                    )?.length > 0
                      ? rows
                          .filter(
                            (row) =>
                              (row.original as Category).parent?.id ===
                              category?.id
                          )
                          .map((row: any) => {
                            row.depth = depth;

                            prepareRow(row);
                            return row.isExpanded
                              ? [
                                  row.cells
                                    .filter(
                                      (cell: TableCell) =>
                                        visibleColumns?.includes(
                                          cell.column.id
                                        ) || cell.column.id === "actions"
                                    )
                                    .map((cell: TableCell) => {
                                      const wrapper = document?.querySelector(
                                        `[data-cell=${cell.column.id.replace(
                                          /[^a-zA-Z]/g,
                                          ""
                                        )}${cell.row.id}] > div`
                                      );

                                      const shouldShowTooltip =
                                        wrapper &&
                                        wrapper.clientWidth <
                                          wrapper.scrollWidth - 10;

                                      return cell.column.id === "actions" ? (
                                        cell.render("Cell")
                                      ) : (
                                        <div
                                          key={cell.value}
                                          data-cell={`${cell.column.id.replace(
                                            /[^a-zA-Z]/g,
                                            ""
                                          )}${cell.row.id}`}
                                        >
                                          {shouldShowTooltip ? (
                                            <Tooltip
                                              ignoreBoundary
                                              content={cell.render("Cell")}
                                            >
                                              <div
                                                data-ui="tooltip-content"
                                                className={css({
                                                  width:
                                                    getColumnWidth(
                                                      cell.column.id
                                                    ) || "200px",
                                                  ...getColumnDataAlign(
                                                    cell.column.id
                                                  ),
                                                  minWidth: "calc(100%)",
                                                  whiteSpace: "nowrap",
                                                  display: "block",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                })}
                                              >
                                                {cell.render("Cell")}
                                              </div>
                                            </Tooltip>
                                          ) : (
                                            <div
                                              className={css({
                                                width:
                                                  getColumnWidth(
                                                    cell.column.id
                                                  ) || "200px",
                                                minWidth: "calc(100%)",
                                                whiteSpace: "nowrap",
                                                display: "block",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                ...getColumnDataAlign(
                                                  cell.column.id
                                                ),
                                              })}
                                            >
                                              {cell.render("Cell")}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }),
                                  ...nestChildrenUnits(row.original, depth + 1),
                                ]
                              : [
                                  row.cells
                                    .filter(
                                      (cell: TableCell) =>
                                        visibleColumns?.includes(
                                          cell.column.id
                                        ) || cell.column.id === "actions"
                                    )
                                    .map((cell: TableCell) => {
                                      const wrapper = document?.querySelector(
                                        `[data-cell=${cell.column.id.replace(
                                          /[^a-zA-Z]/g,
                                          ""
                                        )}${cell.row.id}] > div`
                                      );

                                      const shouldShowTooltip =
                                        wrapper &&
                                        wrapper.clientWidth <
                                          wrapper.scrollWidth - 10;

                                      return cell.column.id === "actions" ? (
                                        cell.render("Cell")
                                      ) : (
                                        <div
                                          key={cell.value}
                                          data-cell={`${cell.column.id.replace(
                                            /[^a-zA-Z]/g,
                                            ""
                                          )}${cell.row.id}`}
                                        >
                                          {shouldShowTooltip ? (
                                            <Tooltip
                                              ignoreBoundary
                                              content={cell.render("Cell")}
                                            >
                                              <div
                                                data-ui="tooltip-content"
                                                className={css({
                                                  width:
                                                    getColumnWidth(
                                                      cell.column.id
                                                    ) || "200px",
                                                  ...getColumnDataAlign(
                                                    cell.column.id
                                                  ),
                                                  minWidth: "calc(100%)",
                                                  whiteSpace: "nowrap",
                                                  display: "block",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                })}
                                              >
                                                {cell.render("Cell")}
                                              </div>
                                            </Tooltip>
                                          ) : (
                                            <div
                                              className={css({
                                                width:
                                                  getColumnWidth(
                                                    cell.column.id
                                                  ) || "200px",
                                                minWidth: "calc(100%)",
                                                whiteSpace: "nowrap",
                                                display: "block",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                ...getColumnDataAlign(
                                                  cell.column.id
                                                ),
                                              })}
                                            >
                                              {cell.render("Cell")}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }),
                                ];
                          })
                          .flat()
                      : [];
                  };

                  return rows
                    .filter((row) => !(row.original as Category).parent?.id)
                    .map((row: any) => {
                      prepareRow(row);
                      return row.isExpanded
                        ? [
                            row.cells
                              .filter(
                                (cell: TableCell) =>
                                  visibleColumns?.includes(cell.column.id) ||
                                  cell.column.id === "actions"
                              )
                              .map((cell: TableCell) => {
                                const wrapper = document?.querySelector(
                                  `[data-cell=${cell.column.id.replace(
                                    /[^a-zA-Z]/g,
                                    ""
                                  )}${cell.row.id}] > div`
                                );

                                const shouldShowTooltip =
                                  wrapper &&
                                  wrapper.clientWidth <
                                    wrapper.scrollWidth - 10;

                                return cell.column.id === "actions" ? (
                                  cell.render("Cell")
                                ) : (
                                  <div
                                    key={cell.value}
                                    data-cell={`${cell.column.id.replace(
                                      /[^a-zA-Z]/g,
                                      ""
                                    )}${cell.row.id}`}
                                  >
                                    {shouldShowTooltip ? (
                                      <Tooltip
                                        ignoreBoundary
                                        content={cell.render("Cell")}
                                      >
                                        <div
                                          data-ui="tooltip-content"
                                          className={css({
                                            width:
                                              getColumnWidth(cell.column.id) ||
                                              "200px",
                                            ...getColumnDataAlign(
                                              cell.column.id
                                            ),
                                            minWidth: "calc(100%)",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          })}
                                        >
                                          {cell.render("Cell")}
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      <div
                                        className={css({
                                          width:
                                            getColumnWidth(cell.column.id) ||
                                            "200px",
                                          minWidth: "calc(100%)",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          ...getColumnDataAlign(cell.column.id),
                                        })}
                                      >
                                        {cell.render("Cell")}
                                      </div>
                                    )}
                                  </div>
                                );
                              }),
                            ...nestChildrenUnits(row.original, 1),
                          ]
                        : [
                            row.cells
                              .filter(
                                (cell: TableCell) =>
                                  visibleColumns?.includes(cell.column.id) ||
                                  cell.column.id === "actions"
                              )
                              .map((cell: TableCell) => {
                                const wrapper = document?.querySelector(
                                  `[data-cell=${cell.column.id.replace(
                                    /[^a-zA-Z]/g,
                                    ""
                                  )}${cell.row.id}] > div`
                                );

                                const shouldShowTooltip =
                                  wrapper &&
                                  wrapper.clientWidth <
                                    wrapper.scrollWidth - 10;

                                return cell.column.id === "actions" ? (
                                  cell.render("Cell")
                                ) : (
                                  <div
                                    key={cell.value}
                                    data-cell={`${cell.column.id.replace(
                                      /[^a-zA-Z]/g,
                                      ""
                                    )}${cell.row.id}`}
                                  >
                                    {shouldShowTooltip ? (
                                      <Tooltip
                                        ignoreBoundary
                                        content={cell.render("Cell")}
                                      >
                                        <div
                                          data-ui="tooltip-content"
                                          className={css({
                                            width:
                                              getColumnWidth(cell.column.id) ||
                                              "200px",
                                            ...getColumnDataAlign(
                                              cell.column.id
                                            ),
                                            minWidth: "calc(100%)",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          })}
                                        >
                                          {cell.render("Cell")}
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      <div
                                        className={css({
                                          width:
                                            getColumnWidth(cell.column.id) ||
                                            "200px",
                                          minWidth: "calc(100%)",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          ...getColumnDataAlign(cell.column.id),
                                        })}
                                      >
                                        {cell.render("Cell")}
                                      </div>
                                    )}
                                  </div>
                                );
                              }),
                          ];
                    })
                    .flat();
                },
              })}
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
