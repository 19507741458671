import { mergeOverrides, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Overrides } from "baseui/overrides";
import {
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
  Value,
} from "baseui/select";
import { LabelSmall } from "baseui/typography";
import React, {
  ChangeEvent,
  FormEvent,
  HTMLProps,
  ReactNodeArray,
  useEffect,
} from "react";
import { useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { VariableSizeList as List } from "react-window";
import { StyleObject } from "styletron-react";
import {
  ChevronDown,
  FolderOff,
  Keyboard,
  ListSearch,
  X,
} from "tabler-icons-react";

export type SelectProps = {
  $style?: StyleObject;
  $controlContainerStyle?: StyleObject;
  $dropdownStyle?: StyleObject;
  objectId?: number;
} & BaseSelectProps;

export type SelectWithActiveProjectProps = SelectProps & {
  activeProjectId?: number;
};

export type SelectValue = {
  id: string | number;
  label?: string;
}[];

const Select = ({
  $style,
  $controlContainerStyle,
  $dropdownStyle,
  overrides,
  isLoading,
  multi,
  size,
  disabled,
  id,
  ...rest
}: SelectProps): React.ReactElement => {
  const [css, theme] = useStyletron();
  const Component = BaseSelect;

  return (
    <Component
      filterOutSelected={false}
      closeOnSelect={!multi}
      maxDropdownHeight="300px"
      noResultsMsg={
        <div
          key="error"
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          {isLoading ? (
            <ListSearch
              color="#999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
          ) : (
            <FolderOff
              color="#999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
          )}

          {isLoading ? "Trwa przeszukiwanie" : "Brak rekordów"}
        </div>
      }
      isLoading={isLoading}
      multi={multi}
      size={size || "compact"}
      disabled={disabled}
      overrides={mergeOverrides<any>(
        {
          Root: {
            style: {
              ...$style,
            },
          },
          ControlContainer: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              transitionDuration: "100ms",
              minHeight: "32px",
              ...$controlContainerStyle,
            },
          },
          ValueContainer: {
            style: {
              height: "32px",
              display: "flex",
              alignItems: "center",
              ...(!multi && { paddingTop: "1px", paddingBottom: "2px" }),
            },
          },
          SingleValue: {
            style: {
              height: "auto",
              fontSize: "12px",
            },
          },
          OptionContent: {
            style: ({ $selected }) => ({
              fontWeight: $selected ? 600 : 400,
              fontSize: "12px",
              ...($selected &&
                multi && {
                  paddingLeft: "20px",
                  "::before": {
                    content: "'✓'",
                    fontSize: "7px",
                    backgroundColor: theme.colors.primary,
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    color: "white",
                    borderRadius: "8px",
                    position: "absolute",
                    height: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "9px",
                    left: "16px",
                  },
                }),
            }),
          },
          LoadingIndicator: {
            props: {
              overrides: {
                ActivePath: {
                  style: {
                    fill: theme.colors.borderFocus,
                  },
                },
                Svg: {
                  style: {
                    height: "16px",
                    marginRight: "5px",
                  },
                },
              },
            },
          },
          SelectArrow: {
            props: {
              overrides: {
                Svg: () => (
                  <ChevronDown color={theme.colors.borderFocus} size={16} />
                ),
              },
            },
          },
          ClearIcon: {
            props: {
              overrides: {
                Svg: (props: HTMLProps<HTMLElement>) => (
                  <span
                    className={css({
                      marginRight: "5px",
                      cursor: "pointer",
                      display: "inline",
                      lineHeight: 0,
                    })}
                    {...props}
                  >
                    <X color={theme.colors.borderFocus} size={16} />
                  </span>
                ),
              },
            },
          },
          DropdownListItem: {
            style: {
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale200,
            },
          },
          Dropdown: {
            style: {
              boxShadow: "unset",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderLeftWidth: "2px",
              borderLeftStyle: "solid",
              borderLeftColor: theme.colors.borderFocus,
              borderBottomWidth: "2px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.colors.borderFocus,
              borderRightWidth: "2px",
              borderRightStyle: "solid",
              borderRightColor: theme.colors.borderFocus,
              borderTopWidth: "2px",
              borderTopStyle: "solid",
              borderTopColor: theme.colors.borderFocus,
              backgroundColor: theme.colors.inputFill,
              ...$dropdownStyle,
            },
          },
          Popover: {
            props: {
              ignoreBoundary: true,
              overrides: {
                Body: {
                  style: {
                    zIndex: 200,
                    boxShadow: "none",
                    marginLeft: "1px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    backgroundColor: "transparent",
                  },
                },
                Inner: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              },
            },
          },
          StatefulMenu: {
            props: {
              overrides: {
                EmptyState: {
                  style: {
                    color: "#999999",
                    paddingTop: theme.sizing.scale300,
                    paddingBottom: theme.sizing.scale300,
                  },
                },
              },
            },
          },
          Input: {
            props: {
              id,
              name: id,
            },
          },
        },
        overrides as Overrides<any>
      )}
      {...rest}
    />
  );
};

export default Select;

export function LiveSearchSelect({
  value,
  onInputChange,
  ...rest
}: SelectProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isSelectedOrSearched, setIsSelectedOrSearched] = useState(false);

  useEffect(() => {
    if (value?.length) {
      setIsSelectedOrSearched(true);
    } else {
      setIsSelectedOrSearched(false);
    }
  }, [value]);

  return (
    <Select
      onInputChange={(event: FormEvent<HTMLInputElement>) => {
        setIsSelectedOrSearched(!!(event.currentTarget.value as string));

        onInputChange && onInputChange(event);
      }}
      value={value}
      {...(!isSelectedOrSearched && {
        overrides: {
          Dropdown: {
            component: (props: HTMLProps<HTMLUListElement>) => {
              return (
                <div
                  className={css({
                    padding: "16px",
                    boxShadow: "none",
                    borderTopLeftRadius: theme.borders.radius200,
                    borderTopRightRadius: theme.borders.radius200,
                    borderBottomRightRadius: theme.borders.radius200,
                    borderBottomLeftRadius: theme.borders.radius200,
                    backgroundColor: "#FDFDFD",
                    borderLeftWidth: "2px",
                    borderLeftStyle: "solid",
                    borderLeftColor: theme.colors.borderFocus,
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.colors.borderFocus,
                    borderRightWidth: "2px",
                    borderRightStyle: "solid",
                    borderRightColor: theme.colors.borderFocus,
                    borderTopWidth: "2px",
                    borderTopStyle: "solid",
                    borderTopColor: theme.colors.borderFocus,
                  })}
                >
                  <Block display="flex" alignItems="center" color="#000">
                    <Keyboard
                      color="#000"
                      size={16}
                      className={css({ marginRight: "5px" })}
                    />

                    <LabelSmall>
                      Rozpocznij wpisywanie, aby przeszukać
                    </LabelSmall>
                  </Block>

                  <Block
                    display="flex"
                    alignItems="center"
                    color="#000"
                    marginTop="12px"
                    marginBottom="10px"
                  >
                    <LabelSmall
                      $style={{
                        flexGrow: 1,
                        paddingTop: "12px",
                        borderTop: "1px solid #eee",
                      }}
                    >
                      Najnowsze
                    </LabelSmall>
                  </Block>

                  <ul
                    className={css({
                      padding: "unset",
                      margin: "unset",
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      borderLeftWidth: "2px",
                      borderLeftStyle: "solid",
                      borderLeftColor: theme.colors.inputBorder,
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.colors.inputBorder,
                      borderRightWidth: "2px",
                      borderRightStyle: "solid",
                      borderRightColor: theme.colors.inputBorder,
                      borderTopWidth: "2px",
                      borderTopStyle: "solid",
                      borderTopColor: theme.colors.inputBorder,
                      backgroundColor: theme.colors.inputFill,
                    })}
                    {...props}
                  />
                </div>
              );
            },
          },
        },
      })}
      {...rest}
    />
  );
}

export function VirtualizedSelect({
  options,
  value,
  onInputChange,
  $dropdownStyle,
  ...rest
}: SelectProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [searchPhrase, setSearchPhrase] = useState<string>();

  function getItemSize(index: number): number {
    const option = (searchPhrase
      ? (options as Value)?.filter((value) =>
          value.label?.toString().includes(searchPhrase)
        )
      : (options as Value))?.[index];

    return (
      Math.max(
        Math.round((option?.label?.toString()?.length || 90) / 32) * 16,
        16
      ) + 32
    );
  }

  return (
    <Select
      onInputChange={(event: FormEvent<HTMLInputElement>) => {
        setSearchPhrase(event.currentTarget.value as string);

        onInputChange && onInputChange(event);
      }}
      value={value}
      options={options}
      {...(!!(searchPhrase
        ? (options as Value)?.filter((value) =>
            value.label?.toString().includes(searchPhrase)
          )
        : (options as Value)
      )?.length && {
        overrides: {
          Dropdown: {
            component: (props: HTMLProps<HTMLUListElement>) => {
              return (
                <div
                  className={css({
                    boxShadow: "unset",
                    borderTopLeftRadius: theme.borders.radius200,
                    borderTopRightRadius: theme.borders.radius200,
                    borderBottomRightRadius: theme.borders.radius200,
                    borderBottomLeftRadius: theme.borders.radius200,
                    borderLeftWidth: "2px",
                    borderLeftStyle: "solid",
                    borderLeftColor: theme.colors.borderFocus,
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.colors.borderFocus,
                    borderRightWidth: "2px",
                    borderRightStyle: "solid",
                    borderRightColor: theme.colors.borderFocus,
                    borderTopWidth: "2px",
                    borderTopStyle: "solid",
                    borderTopColor: theme.colors.borderFocus,
                    backgroundColor: theme.colors.inputFill,
                    ...$dropdownStyle,
                  })}
                >
                  <List
                    height={200}
                    itemCount={
                      (searchPhrase
                        ? (options as Value)?.filter((value) =>
                            value.label?.toString().includes(searchPhrase)
                          )
                        : (options as Value)
                      )?.length as number
                    }
                    itemSize={getItemSize}
                    width={295}
                    className={css({
                      padding: "unset",
                      margin: "unset",
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      borderLeftWidth: "2px",
                      borderLeftStyle: "solid",
                      borderLeftColor: theme.colors.inputBorder,
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.colors.inputBorder,
                      borderRightWidth: "2px",
                      borderRightStyle: "solid",
                      borderRightColor: theme.colors.inputBorder,
                      borderTopWidth: "2px",
                      borderTopStyle: "solid",
                      borderTopColor: theme.colors.inputBorder,
                      backgroundColor: theme.colors.inputFill,
                      position: "relative",
                    })}
                    itemData={props.children}
                  >
                    {({ data, index, style }) => {
                      return (
                        <div style={style}>
                          {(data as ReactNodeArray)[index]}
                        </div>
                      );
                    }}
                  </List>
                </div>
              );
            },
          },
        },
      })}
      {...rest}
    />
  );
}

export function ControlledSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          id={name}
          {...rest}
        />
      )}
    />
  );
}
