import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { Permission, Role } from "../roles";
import { ROLES_FIELDS, RolesFormInputs } from "../roles.form";
import { ROLES_SHOW, ROLES_UPDATE } from "../roles.gql";

export default function RolesUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { checkPermission } = useAuth();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    ...methods
  } = useForm<RolesFormInputs>();

  const { refetch, data, error: queryError } = useQuery(ROLES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const role: Role = data?.role;

  const [updateRole, { error }] = useMutation(ROLES_UPDATE);

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async (values: RolesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateRole({
        variables: {
          roleUpdateInput: {
            id: id ? parseInt(id) : null,
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/roles/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.role) refetch();
    setIsFetching(true);
    setValue("permissionIds", []);
  }, []);

  useEffect(() => {
    if (data && role) {
      setValue("name", role?.name);
      setValue("description", role?.description);
      setValue(
        "permissionIds",
        role?.permissions?.map(({ id }: Permission) => id)
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (errors) scrollOnError(errors, ROLES_FIELDS, "update");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.role.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={role?.name}
        labels={["Role", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateRole",
          },
        ]}
      />
      <Content>
        <FormProvider<RolesFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer<RolesFormInputs>
            id="updateRole"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={ROLES_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji roli"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
