import { useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { formatBytes } from "components/files-picker/files-picker";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useDictionaries } from "contexts/dictionaries-context";
import React, { MouseEvent } from "react";
import { useHistory } from "react-router";
import stc from "string-to-color";
import { StyleObject } from "styletron-standard";
import {
  AlignBoxLeftTop,
  ArrowsRandom,
  Article,
  Book,
  Category,
  CircleCheck,
  CircleX,
  ExternalLink,
  Eye,
  EyeOff,
  Files,
  Ghost,
  Key,
  Lock,
  LockOpen,
  Mail,
  Minus,
  News,
  Photo,
  Pin,
  PinnedOff,
  Seo,
  Shield,
  Slideshow,
  Tags,
  UserCheck,
  UserExclamation,
  UserOff,
  World,
} from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Difficulty, SEO_TAGSETS_SUBJECTS } from "../../constants";

export enum DataType {
  AccessBoolean = "access-boolean",
  ActivityBoolean = "activity-boolean",
  AllProjectsAccess = "all-projects-access",
  Articles = "model:articles",
  Boolean = "boolean",
  Calories = "calories",
  Categories = "model:categories",
  CustomSettings = "model:custom-settings",
  Date = "date",
  DateTime = "datetime",
  Difficulty = "difficulty",
  Dictionaries = "model:dictionaries",
  Email = "email",
  FileDownload = "file-download",
  FileFormat = "file-format",
  FileSize = "file-size",
  Files = "model:files",
  Gram = "gram",
  Images = "model:images",
  InvertedBoolean = "inverted-boolean",
  Language = "language",
  Link = "link",
  Login = "login",
  Minutes = "minutes",
  Model = "model:",
  NotificationContent = "notification-content",
  Quota = "quota",
  Posts = "model:posts",
  Pre = "pre",
  Projects = "model:projects",
  Roles = "model:roles",
  SeoTagsets = "model:seo-tagsets",
  Slides = "model:slides",
  StaticPages = "model:static-pages",
  Subject = "subject",
  System = "system",
  Tags = "model:tags",
  VisibilityBoolean = "visibility-boolean",
  Users = "model:users",
  UserStatus = "user-status",
}

type Props = {
  children?: number | string | boolean | Date;
  data?: string | number;
  dataType?: string;
  deletedAt?: Date;
  href?: string;
  $style?: StyleObject;
  textWhenEmpty?: string;
  disableLink?: boolean;
};

export default function FormattedValue({
  children,
  dataType,
  data,
  deletedAt,
  href,
  $style,
  textWhenEmpty,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const { findValue } = useDictionaries();

  const hasPermission =
    (dataType?.includes(DataType.Articles) &&
      checkPermission(PERMISSIONS.article.read)) ||
    (dataType?.includes(DataType.Categories) &&
      checkPermission(PERMISSIONS.category.read)) ||
    (dataType?.includes(DataType.CustomSettings) &&
      checkPermission(PERMISSIONS.customSetting.read)) ||
    (dataType?.includes(DataType.Dictionaries) &&
      checkPermission(PERMISSIONS.dictionary.read)) ||
    (dataType?.includes(DataType.Files) &&
      checkPermission(PERMISSIONS.file.read)) ||
    (dataType?.includes(DataType.Images) &&
      checkPermission(PERMISSIONS.image.read)) ||
    (dataType?.includes(DataType.Posts) &&
      checkPermission(PERMISSIONS.post.read)) ||
    (dataType?.includes(DataType.Projects) &&
      checkPermission(PERMISSIONS.project.read)) ||
    (dataType?.includes(DataType.Roles) &&
      checkPermission(PERMISSIONS.role.read)) ||
    (dataType?.includes(DataType.SeoTagsets) &&
      checkPermission(PERMISSIONS.seoTagset.read)) ||
    (dataType?.includes(DataType.Slides) &&
      checkPermission(PERMISSIONS.slide.read)) ||
    (dataType?.includes(DataType.StaticPages) &&
      checkPermission(PERMISSIONS.staticPage.read)) ||
    (dataType?.includes(DataType.Tags) &&
      checkPermission(PERMISSIONS.tag.read)) ||
    (dataType?.includes(DataType.Users) &&
      checkPermission(PERMISSIONS.user.read));

  const iconStyle = {
    verticalAlign: "middle",
    marginRight: "10px",
    display: "inline",
  };

  const preStyle = {
    marginTop: "0px",
    marginBottom: "0px",
    fontWeight: 400,
    fontSize: "14px",
    backgroundColor: theme.colors.backgroundSecondary,
    paddingLeft: theme.sizing.scale300,
    paddingRight: theme.sizing.scale300,
    paddingTop: theme.sizing.scale0,
    paddingBottom: theme.sizing.scale0,
    display: "inline",
    borderRadius: theme.borders.radius100,
  };

  if (
    children === null ||
    children === undefined ||
    children === "" ||
    children === 0
  )
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
          ...$style,
        })}
      >
        {textWhenEmpty || (
          <>
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </>
        )}
      </div>
    );
  else if (dataType === DataType.AllProjectsAccess)
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        <World size={18} className={css({ marginRight: "5px" })} />
        {children}
      </div>
    );
  else if (dataType === DataType.AccessBoolean)
    return (
      <Block display="flex" alignItems="center">
        {children ? (
          <Lock
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <LockOpen
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.ActivityBoolean)
    return (
      <Block>
        {children ? (
          <Pin
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <PinnedOff
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Proces aktywny" : "Proces nieaktywny"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.Boolean)
    return (
      <Block>
        {children ? (
          <CircleCheck
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <CircleX
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.Calories) return <span>{children} kcal</span>;
  else if (dataType === DataType.Date)
    return <span>{new Date(String(children)).toLocaleDateString()}</span>;
  else if (dataType === DataType.DateTime)
    return (
      <span className={css({ ...$style })}>
        {new Date(String(children)).toLocaleString()}
      </span>
    );
  else if (dataType === DataType.Difficulty)
    return (
      <>
        {children === Difficulty.Easy ? (
          "Łatwy"
        ) : children === Difficulty.Medium ? (
          "Średnio-zaawansowany"
        ) : children === Difficulty.Hard ? (
          "Wymagający"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              color: "#999999",
              ...$style,
            })}
          >
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </div>
        )}
      </>
    );
  else if (dataType === DataType.Email)
    return (
      <StyledLink
        target="_blank"
        href={`mailto:${children}`}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <Mail size={14} className={css(iconStyle)} />
        {children}
      </StyledLink>
    );
  else if (dataType === DataType.FileDownload)
    return (
      <StyledLink
        href={href}
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          window.open(href);
        }}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          cursor: "pointer",
        }}
      >
        <Tooltip content="Otwórz plik" placement="bottomLeft">
          <span
            className={css({
              display: "flex",
              alignItems: "center",
            })}
          >
            <ExternalLink size={14} className={css(iconStyle)} />

            {children}
          </span>
        </Tooltip>
      </StyledLink>
    );
  else if (dataType === DataType.FileFormat) {
    const array = (children as string)?.split(".");
    const lastElementIndex = array?.length - 1;
    return (
      <div className={css({ ...preStyle })}>
        {array[lastElementIndex]?.toLowerCase()}
      </div>
    );
  } else if (dataType === DataType.FileSize)
    return <span>{formatBytes(children as number)}</span>;
  else if (dataType === DataType.Gram) return <span>{children} g</span>;
  else if (dataType === DataType.InvertedBoolean)
    return (
      <Block>
        {children ? (
          <CircleX
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <CircleCheck
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.Link)
    return (
      <StyledLink
        target="_blank"
        href={href || (children as string)}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <World size={14} className={css(iconStyle)} />
        {children}
      </StyledLink>
    );
  else if (dataType === DataType.Language)
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {((findValue(children as string) as unknown) as string)?.toLowerCase()}
      </div>
    );
  else if (dataType === DataType.Login)
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        <Key size={18} className={css({ marginRight: "5px" })} />
        {children}
      </div>
    );
  else if (dataType === DataType.Minutes) return <span>{children} min.</span>;
  else if (dataType?.includes(DataType.Model))
    return (
      <StyledLink
        {...(hasPermission &&
          !deletedAt && {
            href: href || `/${dataType.split(":")[1]}/${data}`,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push(href || `/${dataType.split(":")[1]}/${data}`);
            },
          })}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...((!hasPermission || !!deletedAt) && {
            cursor: "not-allowed",
          }),
          ...$style,
        }}
      >
        <Tooltip
          {...(!!deletedAt && {
            content: `Usunięto ${new Date(deletedAt)?.toLocaleDateString()}`,
            placement: "left",
          })}
        >
          <span
            {...(dataType === DataType.Projects && {
              className: css({
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }),
            })}
          >
            {dataType?.includes(DataType.Articles) ? (
              <News size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Categories) ? (
              <Category size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.CustomSettings) ? (
              <ArrowsRandom size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Dictionaries) ? (
              <Book size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Files) ? (
              <Files size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Images) ? (
              <Photo size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Posts) ? (
              <AlignBoxLeftTop size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Projects) ? (
              <div
                className={css({
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  backgroundColor: stc(children),
                  flexShrink: 0,
                })}
              />
            ) : dataType?.includes(DataType.Roles) ? (
              <Shield size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.SeoTagsets) ? (
              <Seo size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Slides) ? (
              <Slideshow size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.StaticPages) ? (
              <Article size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Tags) ? (
              <Tags size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Users) ? (
              <BaseAvatar
                name={children as string}
                size={"16px"}
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: stc(children as string),
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline-block",
                    },
                  },
                  Initials: {
                    style: {
                      fontSize: "8px",
                    },
                  },
                }}
              />
            ) : (
              <ExternalLink size={14} className={css(iconStyle)} />
            )}
            {children}
          </span>
        </Tooltip>
      </StyledLink>
    );
  else if (dataType === DataType.NotificationContent)
    return children ? (
      <span>(został) {children}</span>
    ) : (
      <span>(został) przeniesiony</span>
    );
  else if (dataType === DataType.Quota)
    return (
      <span className={css({ ...$style })}>
        {new Intl.NumberFormat("pl-PL", {
          style: "currency",
          currencyDisplay: "code",
          currency: "PLN",
        }).format(parseFloat(children as string))}
      </span>
    );
  else if (dataType === DataType.Pre)
    return (
      <pre
        className={css({
          ...preStyle,
          ...$style,
        })}
      >
        {children}
      </pre>
    );
  else if (dataType === DataType.Subject)
    return (
      <span>
        {
          SEO_TAGSETS_SUBJECTS.find(
            ({ id }: { id: string; label: string }) => id === children
          )?.label
        }
      </span>
    );
  else if (dataType === DataType.System)
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
        })}
      >
        <Ghost
          color="#999999"
          size={18}
          className={css({ marginRight: "5px" })}
        />
        System
      </div>
    );
  else if (dataType === DataType.VisibilityBoolean)
    return (
      <Block display="flex" alignItems="center">
        {children ? (
          <Eye
            size={14}
            className={css({
              ...iconStyle,
              color: theme.colors.positive,
            })}
          />
        ) : (
          <EyeOff
            size={14}
            className={css({
              ...iconStyle,
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.UserStatus)
    return (
      <Block>
        {children === "Wysłano zaproszenie" ? (
          <UserExclamation
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.warning,
            })}
          />
        ) : children === "Zablokowany" ? (
          <UserOff
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <UserCheck
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        )}
        {children}
      </Block>
    );
  else return <span>{children}</span>;
}
