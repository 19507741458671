import { styled, useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { PLACEMENT } from "baseui/popover";
import { Skeleton } from "baseui/skeleton";
import { Tag } from "baseui/tag";
import { HeadingSmall, LabelMedium, LabelSmall } from "baseui/typography";
import { SubmenuPopover } from "components/popover";
import { Select } from "components/select";
import { Tooltip } from "components/tooltip";
import { Project } from "containers/Projects/projects";
import { useAuth } from "contexts/auth-context";
import { useProject } from "contexts/project-context";
import React, { Fragment, MouseEvent, useState } from "react";
import { useHistory, useLocation } from "react-router";
import stc from "string-to-color";
import {
  AlignBoxLeftTop,
  ArrowsRandom,
  Article,
  Book,
  Category,
  ChartTreemap,
  ChevronRight,
  CirclePlus,
  ClipboardList,
  Direction,
  Files,
  Icon,
  Logout,
  News,
  Photo,
  Seo,
  Shield,
  Slideshow,
  Tags,
  User,
  Users,
  World,
} from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { APP_NAME, ReactAppSystemVersion } from "../../constants";

const Container = styled("nav", {
  position: "relative",
  backgroundColor: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  height: "100vh",
  paddingBottom: "15px",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRight: "2px inset #eeeeee",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

type NavCategory = {
  id?: string;
  label?: string;
  items: NavItem[];
};

type NavItem = {
  label: string;
  icon: Icon;
  href: string;
  permission?: string;
  exact?: boolean;
  submenu?: NavItem[];
  indicator?: string;
};

const USER_NAV: NavItem[] = [
  {
    label: "Moje konto",
    icon: User,
    href: "/my-account",
  },
  {
    label: "Wyloguj się",
    icon: Logout,
    href: "/logout",
  },
];

export const NAV: NavCategory[] = [
  {
    id: "content",
    label: "Treści",
    items: [
      {
        label: "Aktualności",
        icon: AlignBoxLeftTop,
        href: "/posts",
        permission: PERMISSIONS.post.read,
      },
      {
        label: "Artykuły",
        icon: News,
        href: "/articles",
        permission: PERMISSIONS.article.read,
      },
      {
        label: "Strony informacyjne",
        icon: Article,
        href: "/static-pages",
        permission: PERMISSIONS.staticPage.read,
      },
      {
        label: "Kategorie",
        icon: Category,
        href: "/categories",
        permission: PERMISSIONS.category.read,
      },
      {
        label: "Slajdy",
        icon: Slideshow,
        href: "/slides",
        permission: PERMISSIONS.slide.read,
      },
      {
        label: "Grafiki",
        icon: Photo,
        href: "/images",
        permission: PERMISSIONS.image.read,
      },
      {
        label: "Pliki",
        icon: Files,
        href: "/files",
        permission: PERMISSIONS.file.read,
      },
      {
        label: "Tagi",
        icon: Tags,
        href: "/tags",
        permission: PERMISSIONS.tag.read,
      },
      {
        label: "Inne treści",
        icon: ArrowsRandom,
        href: "/custom-settings",
        permission: PERMISSIONS.customSetting.read,
      },
      {
        label: "Tagi SEO",
        icon: Seo,
        href: "/seo-tagsets",
        permission: PERMISSIONS.seoTagset.read,
      },
    ],
  },
  {
    id: "admin",
    label: "Administracja",
    items: [
      {
        label: "Projekty",
        icon: ChartTreemap,
        href: "/projects",
        permission: PERMISSIONS.project.read,
      },
      {
        label: "Użytkownicy",
        icon: Users,
        href: "/users",
        permission: PERMISSIONS.user.read,
      },
      {
        label: "Role i uprawnienia",
        icon: Shield,
        href: "/roles",
        permission: PERMISSIONS.role.read,
      },
      {
        label: "Słowniki systemowe",
        icon: Book,
        href: "/dictionaries",
        permission: PERMISSIONS.dictionary.read,
      },
      {
        label: "Logi aktywności",
        icon: ClipboardList,
        href: "/activity-logs",
        permission: PERMISSIONS.activityLog.read,
      },
    ],
  },
];

function UserSummary({ width }: SideNavProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  return (
    <div
      className={css({
        backgroundColor: "white",
        height: isOpen ? `${USER_NAV.length * 42 + 52}px` : "57px",
        overflow: "hidden",
        transition: "all 200ms ease",
        flexShrink: 0,
        position: "sticky",
        top: "66px",
        zIndex: 1,
        borderBottom: "2px solid #f9f9f9",
      })}
    >
      <div
        role="button"
        className={css({
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingTop: theme.sizing.scale400,
          paddingBottom: theme.sizing.scale400,
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          ":hover": {
            color: theme.colors.primary,
            backgroundColor: "white",
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={css({
            position: "relative",
            flexShrink: 0,
            marginRight: theme.sizing.scale400,
          })}
        >
          <BaseAvatar
            name={renderUserLabel(user)}
            size={width < 100 ? "26px" : "scale900"}
            overrides={{
              Root: {
                style: {
                  backgroundColor: stc(`${user?.firstName} ${user?.lastName}`),
                },
              },
              Initials: {
                style: {
                  fontSize: width < 100 ? "11px" : "16px",
                },
              },
            }}
          />
        </div>

        <div
          className={css({
            flexShrink: 1,
            flexGrow: 1,
            overflow: "hidden",
          })}
        >
          <LabelMedium
            $style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {" "}
            {renderUserLabel(user)}
          </LabelMedium>
          <LabelSmall
            $style={{
              color: "#aaa",
              marginTop: "0px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {user?.email}
          </LabelSmall>
        </div>
        <Direction
          size={18}
          className={css({ marginLeft: theme.sizing.scale400, flexShrink: 0 })}
        />
      </div>
      <ul
        className={css({
          marginTop: "0px",
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        {USER_NAV &&
          USER_NAV.map((item, index) => {
            const isActive =
              item.href.substring(1).length > 0 &&
              location.pathname.includes(item.href.substring(1));

            if (isActive && isOpen === null) setIsOpen(true);

            return (
              <li
                key={`user-nav` + index}
                className={css({
                  display: "flex",
                  position: "relative",
                  height: "22px",
                  alignItems: "center",
                  marginTop: theme.sizing.scale400,
                  marginBottom: theme.sizing.scale400,
                  paddingLeft: theme.sizing.scale600,
                  paddingRight: theme.sizing.scale600,
                  fontWeight: 500,
                  color: isActive
                    ? theme.colors.primary
                    : theme.colors.contentSecondary,
                  ":hover": {
                    color: isActive
                      ? theme.colors.primary
                      : theme.colors.contentPrimary,
                    cursor: "pointer",
                  },
                })}
                onClick={() => history.push(item.href)}
              >
                {width < 100 ? (
                  <Tooltip
                    content={item.label}
                    placement="left"
                    $style={{
                      left: "198px",
                      right: "unset",
                      width: "200px",
                      display: "flex",
                      justifyContent: "flex-start",
                      zIndex: 40,
                    }}
                  >
                    <div
                      className={css({
                        marginRight: "10px",
                        height: "20px",
                        marginLeft: "2px",
                        position: "relative",
                      })}
                    >
                      <item.icon size={20} />
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    <div
                      className={css({ marginRight: "10px", height: "20px" })}
                    >
                      <item.icon size={22} />
                    </div>

                    {item.label}
                  </>
                )}

                {isActive && (
                  <span
                    className={css({
                      position: "absolute",
                      zIndex: 2,
                      right: "0px",
                      height: "100%",
                      width: "2px",
                      backgroundColor: theme.colors.primary,
                    })}
                  ></span>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

type SideNavElementProps = {
  children?: React.ReactChild;
  isActive?: boolean;
  width: number;
  label: string;
  isSubmenu?: boolean;
  hasSubmenu?: boolean;
  color?: string;
};

function SideNavElement({
  children,
  isActive,
  width,
  label,
  isSubmenu,
  hasSubmenu,
  color,
}: SideNavElementProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <li
      className={css({
        display: "flex",
        position: "relative",
        height: "22px",
        alignItems: "center",
        marginTop: theme.sizing.scale400,
        marginBottom: theme.sizing.scale400,
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        fontWeight: 500,
        color: isActive
          ? color || theme.colors.primary
          : theme.colors.contentSecondary,
        ":hover": {
          color: isActive
            ? color || theme.colors.primary
            : theme.colors.contentPrimary,
          cursor: "pointer",
        },
      })}
    >
      {width < 100 && !isSubmenu ? (
        <Tooltip
          content={label}
          placement="left"
          $style={{
            left: "198px",
            right: "unset",
            width: "200px",
            display: "flex",
            justifyContent: "flex-start",
            zIndex: 40,
          }}
        >
          <div
            className={css({
              marginRight: "10px",
              height: "20px",
              marginLeft: "2px",
            })}
          >
            {hasSubmenu ? (
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {children}
                <ChevronRight size={14} />
              </Block>
            ) : (
              children
            )}
          </div>
        </Tooltip>
      ) : (
        <Fragment>
          <div
            className={css({
              marginRight: "10px",
              height: "20px",
            })}
          >
            {children}
          </div>

          <div
            className={css({
              width: "100%",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "0",
            })}
          >
            <span
              className={css({
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              })}
            >
              {label}
            </span>

            {hasSubmenu && <ChevronRight size={12} />}
          </div>
        </Fragment>
      )}

      {isActive && !isSubmenu && (
        <span
          className={css({
            position: "absolute",
            right: "0px",
            height: "100%",
            width: "2px",
            backgroundColor: color || theme.colors.primary,
          })}
        ></span>
      )}
    </li>
  );
}

type SideNavProps = {
  width: number;
};

export default function SideNav({ width }: SideNavProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const history = useHistory();
  const location = useLocation();
  const { user, checkPermission } = useAuth();

  const permissionsList = user?.permissions?.map(
    (permission) => permission.systemName
  );

  const systemVersion = process.env.REACT_APP_SYSTEM_VERSION;

  const {
    isFetchingProject,
    activeProjectId,
    activeProject,
    myProjects,
    changeActiveProjectId,
  } = useProject();

  return (
    <Container>
      <StyledLink
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push("/");
        }}
        href={"/"}
        $style={{
          textDecoration: "none",
          position: "sticky",
          top: "0px",
          zIndex: 1,
        }}
      >
        <HeadingSmall
          paddingTop="scale600"
          paddingBottom="scale600"
          paddingLeft="scale600"
          paddingRight="scale600"
          marginTop="0px"
          marginBottom="0px"
          position="sticky"
          backgroundColor="#f9f9f9"
          top="0px"
          $style={{
            height: "66px",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(width < 100 && { fontSize: "14px" }),
          }}
        >
          {width > 100 ? APP_NAME : "GCMS"}

          {systemVersion !== ReactAppSystemVersion.PRODUCTION &&
            (width > 100 ? (
              <Tag
                closeable={false}
                {...(systemVersion === ReactAppSystemVersion.TEST && {
                  kind: "negative",
                })}
                {...(systemVersion === ReactAppSystemVersion.TRAINING && {
                  kind: "positive",
                })}
                {...(!systemVersion && {
                  kind: "warning",
                })}
                variant="solid"
                overrides={{
                  Text: { style: { fontSize: "12px", fontWeight: 600 } },
                  Root: { style: { marginLeft: "8px" } },
                }}
              >
                {systemVersion === ReactAppSystemVersion.TEST && "TEST"}
                {systemVersion === ReactAppSystemVersion.TRAINING &&
                  "SZKOLENIE"}
                {!systemVersion && "DEV"}
              </Tag>
            ) : (
              <div
                className={css({
                  position: "absolute",
                  bottom: "12px",
                  borderRadius: "5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginLeft: "1px",
                  flexShrink: 0,
                  display: "flex",
                  fontSize: "9px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  height: "12px",
                  lineHeight: "12px",
                  color: "white",
                  fontWeight: 700,
                  ...(systemVersion === ReactAppSystemVersion.TEST && {
                    backgroundColor: theme.colors.negative,
                  }),
                  ...(systemVersion === ReactAppSystemVersion.TRAINING && {
                    backgroundColor: theme.colors.positive,
                  }),
                  ...(!systemVersion && {
                    backgroundColor: "rgb(255, 192, 67)",
                    color: "rgb(103, 77, 27)",
                  }),
                })}
              >
                {systemVersion === ReactAppSystemVersion.TEST && "TEST"}
                {systemVersion === ReactAppSystemVersion.TRAINING && "SZKOL."}
                {!systemVersion && "DEV"}
              </div>
            ))}
        </HeadingSmall>
      </StyledLink>

      <UserSummary width={width} />

      {width > 100 && (
        <div
          className={css({
            paddingTop: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale600,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            position: "sticky",
            top: "122px",
            ...(activeProject && {
              background: `linear-gradient(0deg, ${stc(
                activeProject.name
              )}20 0%, ${stc(activeProject.name)}30 100%)`,
            }),
            zIndex: 10,
          })}
        >
          {isFetchingProject ? (
            <Skeleton
              width="100%"
              height="36px"
              overrides={{ Root: { style: { borderRadius: "20.1px" } } }}
            />
          ) : (
            <Select
              options={[
                { id: 0, label: "Widok globalny" },
                ...(myProjects
                  ? myProjects.map((project: Project) => ({
                      id: project.id,
                      label: project.name,
                    }))
                  : []),
                ...(checkPermission(PERMISSIONS.project.create)
                  ? [
                      {
                        id: "NEW",
                        label: "Nowy projekt",
                      },
                    ]
                  : []),
              ]}
              value={[{ id: activeProjectId || 0 }]}
              onChange={(params) => {
                if (params.option?.id === "NEW") {
                  history.push("/projects/create");
                  return;
                }

                changeActiveProjectId(params.option?.id as number);
              }}
              data-test-id="projects-select"
              getValueLabel={({ option }) => (
                <Block display="flex" alignItems="center">
                  {option?.id === 0 ? (
                    <World size={16} />
                  ) : option?.id === "NEW" ? (
                    <CirclePlus size={16} />
                  ) : (
                    <div
                      className={css({
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        backgroundColor: stc(option?.label),
                        flexShrink: 0,
                      })}
                    />
                  )}

                  <LabelSmall $style={{ marginLeft: "10px" }}>
                    {option?.label}
                  </LabelSmall>
                </Block>
              )}
              getOptionLabel={({ option }) => (
                <Block display="flex" alignItems="center" position="relative">
                  {option?.id === 0 ? (
                    <World size={12} />
                  ) : option?.id === "NEW" ? (
                    <CirclePlus size={12} />
                  ) : (
                    <div
                      className={css({
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        backgroundColor: stc(option?.label),
                        flexShrink: 0,
                      })}
                    />
                  )}

                  <LabelSmall $style={{ marginLeft: "8px" }}>
                    {option?.label}
                  </LabelSmall>
                </Block>
              )}
              clearable={false}
              searchable={false}
              $controlContainerStyle={{
                borderRadius: "20px",
                background: "white",
              }}
              $dropdownStyle={{
                borderRadius: "20px",
              }}
            />
          )}
        </div>
      )}

      {NAV &&
        NAV.filter(
          (item) =>
            item.items.filter(
              (item) =>
                (item.permission &&
                  permissionsList?.includes(item.permission)) ||
                !item.permission
            ).length !== 0
        ).map((category, index) => (
          <div
            key={`nav-category` + index}
            className={css({
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale400,
              ...(category.id === "content" &&
                activeProject && {
                  background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${stc(
                    activeProject.name
                  )}20 100%)`,
                }),
            })}
          >
            {category.label &&
              category.items.filter(
                (item) =>
                  (item.permission &&
                    permissionsList?.includes(item.permission)) ||
                  !item.permission
              ).length !== 0 && (
                <LabelSmall
                  color="contentTertiary"
                  marginBottom="scale700"
                  $style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    paddingLeft: width > 100 ? theme.sizing.scale600 : "23px",
                    paddingRight: theme.sizing.scale600,
                    whiteSpace: "nowrap",
                  }}
                >
                  {width > 100 ? category.label : "—"}
                </LabelSmall>
              )}
            <ul
              className={css({
                marginTop: "0px",
                paddingLeft: 0,
                paddingRight: 0,
              })}
            >
              {category.items
                .filter(
                  (item) =>
                    (item.permission &&
                      permissionsList?.includes(item.permission)) ||
                    !item.permission
                )
                .map((item, subindex) => {
                  const isItemActive =
                    (item.href.substring(1).length > 0 &&
                      ((item.exact && location.pathname === item.href) ||
                        (!item.exact &&
                          location.pathname.includes(item.href)))) ||
                    item?.submenu?.some(
                      (subitem) =>
                        subitem.href.substring(1).length > 0 &&
                        ((subitem.exact &&
                          location.pathname === subitem.href) ||
                          (!subitem.exact &&
                            location.pathname.includes(subitem.href)))
                    );
                  {
                    if (item.submenu?.length)
                      return (
                        <SubmenuPopover
                          key={`nav-category` + index + `nav-item` + subindex}
                          placement={PLACEMENT.left}
                          content={({ close }) =>
                            item.submenu?.map((subitem, subindex) => {
                              const isSubitemActive =
                                subitem.href.substring(1).length > 0 &&
                                ((subitem.exact &&
                                  location.pathname === subitem.href) ||
                                  (!subitem.exact &&
                                    location.pathname.includes(subitem.href)));
                              return (
                                <StyledLink
                                  key={
                                    `nav-category` +
                                    index +
                                    `nav-item` +
                                    subindex
                                  }
                                  onClick={(event: MouseEvent) => {
                                    event.preventDefault();
                                    history.push(subitem.href);
                                    close();
                                  }}
                                  href={subitem.href}
                                  $style={{ textDecoration: "none" }}
                                >
                                  <SideNavElement
                                    isActive={isSubitemActive}
                                    label={subitem.label}
                                    width={width}
                                    isSubmenu
                                  >
                                    <subitem.icon size={20} />
                                  </SideNavElement>
                                </StyledLink>
                              );
                            })
                          }
                        >
                          <div>
                            <SideNavElement
                              isActive={isItemActive}
                              label={item.label}
                              width={width}
                              hasSubmenu
                            >
                              <item.icon size={20} />
                            </SideNavElement>
                          </div>
                        </SubmenuPopover>
                      );

                    return (
                      <StyledLink
                        key={`nav-category` + index + `nav-item` + subindex}
                        onClick={(event: MouseEvent) => {
                          event.preventDefault();
                          history.push(item.href);
                        }}
                        href={item.href}
                        $style={{ textDecoration: "none" }}
                      >
                        <SideNavElement
                          isActive={isItemActive}
                          label={item.label}
                          width={width}
                          {...(category.id === "content" &&
                            activeProject && {
                              color: stc(activeProject.name),
                            })}
                        >
                          <item.icon size={20} />
                        </SideNavElement>
                      </StyledLink>
                    );
                  }
                })}
            </ul>
          </div>
        ))}

      {width > 100 && (
        <LabelSmall
          marginTop="auto"
          paddingTop={theme.sizing.scale600}
          paddingLeft={theme.sizing.scale600}
          paddingRight={theme.sizing.scale600}
          color="contentInverseTertiary"
        >
          © {new Date().getFullYear()} • {process.env.REACT_APP_VERSION}
        </LabelSmall>
      )}
    </Container>
  );
}
