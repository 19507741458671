export const PERMISSIONS = {
  activityLog: {
    read: "activityLog.read",
  },
  article: {
    read: "article.read",
    update: "article.update",
    create: "article.create",
    delete: "article.delete",
  },
  category: {
    read: "category.read",
    update: "category.update",
    create: "category.create",
    delete: "category.delete",
  },
  customSetting: {
    read: "category.read",
    update: "customSetting.update",
    create: "customSetting.create",
    delete: "customSetting.delete",
  },
  dictionary: {
    read: "dictionary.read",
    valueUpdate: "dictionaryValue.update",
    valueCreate: "dictionaryValue.create",
    valueDelete: "dictionaryValue.delete",
  },
  image: {
    read: "image.read",
    update: "image.update",
    upload: "image.upload",
    delete: "image.delete",
  },
  file: {
    read: "file.read",
    update: "file.update",
    create: "file.create",
    delete: "file.delete",
  },
  post: {
    read: "post.read",
    update: "post.update",
    create: "post.create",
    delete: "post.delete",
  },
  project: {
    read: "project.read",
    update: "project.update",
    create: "project.create",
    delete: "project.delete",
  },
  role: {
    read: "role.read",
    update: "role.update",
    create: "role.create",
    delete: "role.delete",
  },
  slide: {
    read: "slide.read",
    update: "slide.update",
    create: "slide.create",
    delete: "slide.delete",
  },
  staticPage: {
    read: "staticPage.read",
    update: "staticPage.update",
    create: "staticPage.create",
    delete: "staticPage.delete",
  },
  tag: {
    read: "tag.read",
    update: "tag.update",
    create: "tag.create",
    delete: "tag.delete",
  },
  seoTagset: {
    read: "seoTagset.read",
    update: "seoTagset.update",
  },
  user: {
    block: "user.block",
    create: "user.create",
    delete: "user.delete",
    read: "user.read",
    unblock: "user.unblock",
    update: "user.update",
  },
};
