import { gql } from "@apollo/client";

export const USERS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: UserFilter
    $sorting: [UserSort!]
  ) {
    users(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        firstName
        lastName
        email
        description
        blockedAt
        activatedAt
        lastLoginAt
        deletedAt
        createdAt
        updatedAt
        invitedAt
        roles {
          id
          name
        }
        projects {
          id
          name
        }
        hasAllProjectsAccess
      }
    }
  }
`;

export const USERS_LIVE_SEARCH = gql`
  query($offset: Int, $filter: UserFilter, $sorting: [UserSort!]) {
    users(
      paging: { limit: 5, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      nodes {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const USERS_SHOW = gql`
  query UserShow($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      description
      email
      blockedAt
      activatedAt
      lastLoginAt
      deletedAt
      createdAt
      updatedAt
      invitedAt
      roles {
        id
        name
      }
      permissions {
        id
      }
      projects {
        id
        name
      }
      hasAllProjectsAccess
    }
  }
`;

export const USERS_CREATE = gql`
  mutation UserCreate($userCreateInput: UserCreateInput!) {
    userCreate(userCreateInput: $userCreateInput) {
      id
    }
  }
`;

export const USERS_UPDATE = gql`
  mutation UserUpdate($userUpdateInput: UserUpdateInput!) {
    userUpdate(userUpdateInput: $userUpdateInput) {
      id
    }
  }
`;

export const USERS_DELETE = gql`
  mutation UserDelete($userDeleteInput: UserDeleteInput!) {
    userDelete(userDeleteInput: $userDeleteInput) {
      id
    }
  }
`;

export const USERS_BLOCK = gql`
  mutation UserBlock($userBlockInput: UserBlockInput!) {
    userBlock(userBlockInput: $userBlockInput) {
      id
    }
  }
`;

export const USERS_UNBLOCK = gql`
  mutation UserUnblock($userUnblockInput: UserUnblockInput!) {
    userUnblock(userUnblockInput: $userUnblockInput) {
      id
    }
  }
`;

export const USERS_EXPORT = gql`
  mutation UsersExport($filter: UserFilter, $sorting: [UserSort!]) {
    usersExport(paging: { limit: 100000 }, sorting: $sorting, filter: $filter)
  }
`;

export const USERS_RESEND_INVITATION = gql`
  mutation UserResendInvitation($id: Int!) {
    userResendInvitation(id: $id) {
      id
    }
  }
`;
