import { gql } from "@apollo/client";

export const POSTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: PostFilter
    $sorting: [PostSort!]
  ) {
    posts(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        publishedAt
        createdAt
        project {
          id
          name
        }
        language
        createdBy {
          id
          firstName
          lastName
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          email
        }
        isVisible
        slug
      }
    }
  }
`;

export const POSTS_SHOW = gql`
  query PostShow($id: Int!) {
    post(id: $id) {
      id
      name
      slug
      lead
      content
      isVisible
      createdAt
      readTime
      imageUrl
      publishedAt
      project {
        id
        name
      }
      language
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
      tags {
        id
        name
      }
      categories {
        id
        name
      }
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
    }
  }
`;

export const POSTS_SELECT = gql`
  query($filter: PostFilter) {
    posts(paging: { limit: 10000 }, filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

export const POSTS_CREATE = gql`
  mutation PostCreate(
    $postCreateInput: PostCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    postCreate(
      postCreateInput: $postCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const POSTS_UPDATE = gql`
  mutation PostUpdate(
    $postUpdateInput: PostUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    postUpdate(
      postUpdateInput: $postUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const POSTS_DELETE = gql`
  mutation PostDelete($postDeleteInput: PostDeleteInput!) {
    postDelete(postDeleteInput: $postDeleteInput) {
      id
    }
  }
`;
