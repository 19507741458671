import { ApolloError, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Tag } from "baseui/tag";
import { HeadingLarge, LabelLarge, LabelSmall } from "baseui/typography";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { FormControl } from "components/form-control";
import { ForgotPasswordModal } from "components/form-modal";
import { Grid } from "components/grid";
import { ControlledInput } from "components/input";
import { LoadingBar } from "components/loading-bar";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowRight } from "tabler-icons-react";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";
import { FORM_VALIDATION_PATTERNS } from "utils/form/validation-patterns";

import {
  AUTH_TOKEN,
  INPUT_VALIDATION_ERROR,
  ReactAppSystemVersion,
} from "../../../constants";
import { LOGIN } from "../auth.gql";

type LoginFormInputs = {
  email: string;
  password: string;
};

enum LoginField {
  Email = "email",
  Password = "password",
}

export default function Login(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { setIsAuthenticated, setUser } = useAuth();
  const { isLoading, setIsLoading } = useLoading();
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(
    false
  );

  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormInputs>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const history = useHistory();

  type LocationState = {
    from: { pathname: string; search: string };
  };

  const { state } = useLocation<LocationState>();
  const statePathname = state?.from?.pathname;
  const stateSearch = state?.from?.search;

  const [loginUser, { error }] = useMutation(LOGIN);

  const onSubmit = async (values: LoginFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await loginUser({
        variables: {
          loginInput: values,
        },
      });

      localStorage.setItem(AUTH_TOKEN, response?.data?.login?.accessToken);
      setUser(response?.data?.login?.user);
      setIsAuthenticated(true);

      enqueueSnackbar({
        message: "Zalogowano pomyślnie",
        variant: "success",
      });

      statePathname
        ? history.push(`${statePathname}${stateSearch || ""}`)
        : history.push("/");
    } catch (error: unknown) {
      (error as ApolloError)?.graphQLErrors?.map(({ message }) => {
        return enqueueSnackbar({
          message,
          variant: "error",
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const systemVersion = process.env.REACT_APP_SYSTEM_VERSION;

  return (
    <main>
      <LoadingBar />
      <Grid
        isFlex
        $style={{ height: "calc(100vh - 5px)" }}
        flexGridColumnGap={0}
      >
        <Cell isFlex>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingLeft: "50px",
              paddingRight: "50px",
            })}
          >
            <div
              className={css({
                width: "100%",
                maxWidth: "400px",
              })}
            >
              <HeadingLarge
                marginTop="scale0"
                marginBottom="scale1000"
                $style={{ textAlign: "center" }}
              >
                Logowanie
              </HeadingLarge>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  label="Adres e-mail"
                  required
                  error={
                    (errors.email && errors.email.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        INPUT_VALIDATION_ERROR &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === LoginField.Email
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                          </div>
                        ))[0])
                  }
                  disabled={isLoading}
                >
                  <ControlledInput
                    control={control}
                    name={LoginField.Email}
                    rules={{
                      required: FORM_VALIDATION_MESSAGES.required,
                      pattern: {
                        value: FORM_VALIDATION_PATTERNS.email,
                        message: FORM_VALIDATION_MESSAGES.incorrectEmail,
                      },
                    }}
                  />
                </FormControl>
                <FormControl
                  label="Hasło"
                  required
                  error={
                    (errors.password && errors.password.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        INPUT_VALIDATION_ERROR &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === LoginField.Password
                        )
                        ?.errors.map((message: string) => <>{message}</>)[0])
                  }
                  disabled={isLoading}
                >
                  <ControlledInput
                    control={control}
                    type="password"
                    name={LoginField.Password}
                    autoComplete="current-password"
                    rules={{
                      required: FORM_VALIDATION_MESSAGES.required,
                    }}
                  />
                </FormControl>
                <Button
                  type="submit"
                  size={SIZE.large}
                  kind={KIND.primary}
                  isSelected={isLoading}
                  isLoading={isLoading}
                  $style={{ width: "100%", marginTop: "15px" }}
                >
                  Zaloguj się
                </Button>
                <StyledLink
                  onClick={() => setIsForgotPasswordModalOpen(true)}
                  $style={{
                    marginTop: "15px",
                    display: "block",
                    cursor: "pointer",
                  }}
                >
                  Nie pamiętam hasła
                </StyledLink>
              </form>
            </div>
          </div>
        </Cell>
        <Cell
          isFlex
          $style={{
            backgroundColor: "#f9f9f9",
          }}
        >
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              height: "calc(100vh - 5px)",
              padding: "50px",
              maxWidth: "650px",
            })}
          >
            <div>
              <div
                className={css({
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  marginBottom: "20px",
                })}
              >
                <Block display="flex" alignItems="center">
                  <LabelLarge
                    marginTop="scale0"
                    $style={{ textAlign: "center" }}
                  >
                    Global CMS
                  </LabelLarge>
                  {process.env.REACT_APP_SYSTEM_VERSION !==
                    ReactAppSystemVersion.PRODUCTION && (
                    <Tag
                      closeable={false}
                      {...(systemVersion === ReactAppSystemVersion.TEST && {
                        kind: "negative",
                      })}
                      {...(systemVersion === ReactAppSystemVersion.TRAINING && {
                        kind: "positive",
                      })}
                      variant="solid"
                      overrides={{
                        Text: { style: { fontSize: "12px", fontWeight: 600 } },
                        Root: { style: { marginLeft: "8px" } },
                      }}
                    >
                      {systemVersion === ReactAppSystemVersion.TEST && "TEST"}
                      {systemVersion === ReactAppSystemVersion.TRAINING &&
                        "SZKOLENIE"}
                    </Tag>
                  )}
                </Block>
                <LabelSmall
                  paddingTop={theme.sizing.scale200}
                  paddingRight={theme.sizing.scale600}
                  color="contentInverseTertiary"
                >
                  © {new Date().getFullYear()} • {process.env.REACT_APP_VERSION}
                </LabelSmall>
              </div>
              <Button
                type="submit"
                size={SIZE.compact}
                kind={KIND.secondary}
                $style={{ marginTop: "45px" }}
                endEnhancer={() => <ArrowRight size={18} />}
                $as="a"
                target="_blank"
                href=""
              >
                Link 1
              </Button>
              <Button
                type="submit"
                size={SIZE.compact}
                kind={KIND.secondary}
                $style={{ marginTop: "15px", marginLeft: "15px" }}
                endEnhancer={() => <ArrowRight size={18} />}
                $as="a"
                href=""
              >
                Link 2
              </Button>
            </div>
          </div>
        </Cell>
        <ForgotPasswordModal
          isOpen={isForgotPasswordModalOpen}
          close={() => setIsForgotPasswordModalOpen(false)}
        />
      </Grid>
    </main>
  );
}
