import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { SlideVariantSelectorValue } from "components/slide-variant-selector/slide-variant-selector";
import { Project } from "containers/Projects/projects";
import { FieldsGroup, FieldType } from "fields.d";

export type SlidesFormInputs = {
  project: Project[];
  language: SelectValue;
  title: string;
  position: string | number;
  lead: string;
  isVisible: boolean;
  image: File;
  url: string;
  slideVariant: SlideVariantSelectorValue;
};

export enum SlidesField {
  Language = "language",
  Project = "project",
  Title = "title",
  Lead = "lead",
  ImageUrl = "imageUrl",
  Image = "image",
  Url = "url",
  Article = "article",
  Post = "post",
  StaticPage = "staticPage",
  IsVisible = "isVisible",
  Position = "position",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  DeletedAt = "deletedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  SlideVariant = "slideVariant",
}

export const SLIDES_FIELDS: FieldsGroup[] = [
  {
    id: SlidesField.Project,
    label: "",
    fields: [
      {
        id: SlidesField.Project,
        label: "Projekt",
        span: 6,
        type: FieldType.ProjectsSelect,
        dataType: DataType.Projects,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SlidesField.Language,
        label: "Wersja językowa",
        span: 6,
        type: FieldType.ProjectLanguagesSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Lista zawiera języki przypisane do wybranego projektu",
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: SlidesField.Title,
        label: "Tytuł",
        autocomplete: "title",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: SlidesField.Lead,
        label: "Opis",
        type: FieldType.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: SlidesField.SlideVariant,
        label: "Wariant",
        span: 12,
        type: FieldType.SlideVariant,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: SlidesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SlidesField.Position,
        label: "Pozycja",
        type: FieldType.NumberInput,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        caption: "Pozycja określa kolejność wyświetlania slajdów",
      },
      {
        id: SlidesField.Image,
        label: "Grafika",
        span: 12,
        type: FieldType.ImageCropper,
        imageRequirements: { aspect: 2 },
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: SlidesField.ImageUrl,
        label: "Grafika",
        span: 12,
        type: FieldType.Image,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: SlidesField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SlidesField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SlidesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SlidesField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
