import { gql } from "@apollo/client";

export const PROJECTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: ProjectFilter
    $sorting: [ProjectSort!]
  ) {
    projects(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        isActive
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const MY_PROJECTS = gql`
  query {
    myProjects(
      paging: { limit: 50 }
      sorting: { field: name, direction: ASC }
    ) {
      totalCount
      nodes {
        id
        name
      }
    }
  }
`;

export const PROJECTS_SHOW = gql`
  query ProjectShow($id: Int!) {
    project(id: $id) {
      id
      name
      isActive
      allowedDomains
      apiToken
      languages
      mainLanguage
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        email
      }
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
      users {
        id
        firstName
        lastName
        email
        roles {
          id
          name
        }
        createdAt
        blockedAt
        invitedAt
        activatedAt
        deletedAt
      }
    }
  }
`;

export const PROJECT_LANGUAGES = gql`
  query ProjectLanguages($id: Int!) {
    project(id: $id) {
      languages
    }
  }
`;

export const PROJECTS_SELECT = gql`
  query {
    projects(filter: { isActive: { is: true } }) {
      totalCount
      nodes {
        id
        name
        languages
      }
    }
  }
`;

export const PROJECTS_CREATE = gql`
  mutation ProjectCreate(
    $projectCreateInput: ProjectCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    projectCreate(
      projectCreateInput: $projectCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const PROJECTS_UPDATE = gql`
  mutation ProjectUpdate(
    $projectUpdateInput: ProjectUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    projectUpdate(
      projectUpdateInput: $projectUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const PROJECTS_DELETE = gql`
  mutation ProjectDelete($projectDeleteInput: ProjectDeleteInput!) {
    projectDelete(projectDeleteInput: $projectDeleteInput) {
      id
    }
  }
`;

export const PROJECTS_REGENERATE_TOKEN = gql`
  mutation ProjectRegenerateApiToken($id: Int!) {
    projectRegenerateApiToken(id: $id) {
      apiToken
    }
  }
`;
