import { BasicFilter, FilterType } from "./../../filters.d";
import { UsersField } from "./users.form";

export const USERS_FILTERS: BasicFilter[] = [
  {
    id: UsersField.FirstName,
    label: "Imię",
    type: FilterType.Text,
  },
  {
    id: UsersField.LastName,
    label: "Nazwisko",
    type: FilterType.Text,
  },
  {
    id: UsersField.Description,
    label: "Opis",
    type: FilterType.Text,
  },
  {
    id: UsersField.Email,
    label: "Adres e-mail",
    type: FilterType.Text,
  },
  {
    id: UsersField.Projects,
    label: "Projekt",
    type: FilterType.UsersProject,
  },
  {
    id: UsersField.Roles,
    label: "Rola",
    type: FilterType.Role,
  },
  {
    id: UsersField.CreatedAt,
    label: "Utworzenia",
    type: FilterType.Date,
  },
  {
    id: UsersField.InvitedAt,
    label: "Wysłanie zaproszenia",
    type: FilterType.Date,
  },
  {
    id: UsersField.ActivatedAt,
    label: "Aktywacja konta",
    type: FilterType.Date,
  },
  {
    id: UsersField.BlockedAt,
    label: "Zablokowanie",
    type: FilterType.Date,
  },
  {
    id: UsersField.LastLoginAt,
    label: "Ostatnie logowanie",
    type: FilterType.Date,
  },
];
