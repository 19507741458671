import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Pencil, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Tag } from "../tags";
import { TAGS_FIELDS } from "../tags.form";
import { TAGS_DELETE, TAGS_SHOW } from "../tags.gql";

export default function TagsShow(): React.ReactElement {
  const [, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(TAGS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const tag: Tag = data?.tag;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.tag) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.tag && setIsFetching(false), [data]);

  const [deleteTag] = useMutation(TAGS_DELETE);

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await deleteTag({
        variables: {
          tagDeleteInput: {
            id: id ? parseInt(id) : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });
      history.push("/tags");
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.tag.read)) return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={tag?.name}
        labels={["Tagi", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.tag.update),
            onClick: () => history.push(`/tags/${id}/update`),
          },
        ]}
        actions={[
          {
            label: "Usuń tag",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission: checkPermission(PERMISSIONS.tag.delete),
          },
        ]}
      />
      <Content>
        <ObjectViewRenderer<Tag> fields={TAGS_FIELDS} data={tag} />

        <ConfirmDialog
          isOpen={isConfirmDeleteDialogOpen}
          label={`Usunięcie tagu ${tag?.name}`}
          close={() => setIsConfirmDeleteDialogOpen(false)}
          confirm={onSubmit}
        />
      </Content>
    </article>
  );
}
