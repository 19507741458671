import { BasicFilter, FilterType } from "../../filters.d";
import { PostsField } from "./posts.form";

export const POSTS_FILTERS: BasicFilter[] = [
  {
    id: PostsField.Project,
    label: "Projekt",
    type: FilterType.Project,
  },
  {
    id: PostsField.Language,
    label: "Wersja językowa",
    type: FilterType.Language,
  },
  {
    id: PostsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: PostsField.Slug,
    label: "Slug",
    type: FilterType.Text,
  },
  {
    id: PostsField.PublishedAt,
    label: "Publikacja",
    type: FilterType.Date,
  },
  {
    id: PostsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: PostsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: PostsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: PostsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: PostsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
