import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { SelectValue } from "components/select/select";
import { useAuth } from "contexts/auth-context";
import { useDictionaries } from "contexts/dictionaries-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Project } from "../projects";
import {
  PROJECTS_FIELDS,
  ProjectsField,
  ProjectsFormInputs,
} from "../projects.form";
import { PROJECTS_SHOW, PROJECTS_UPDATE } from "../projects.gql";

export default function ProjectsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { findValue } = useDictionaries();
  const [originalLanguages, setOriginalLanguages] = useState<string[]>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<ProjectsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(PROJECTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const project: Project = data?.project;

  useEffect(() => {
    setIsFetching(true);
    if (data?.project) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (data?.project) {
      setIsFetching(false);

      setOriginalLanguages(project?.languages);

      setValue("name", project?.name);
      project?.isActive && setValue("isActive", project?.isActive);
      project?.allowedDomains &&
        setValue(
          "allowedDomains",
          project?.allowedDomains?.toString().replaceAll(/,/g, "\n")
        );
      setValue(
        "languages",
        project?.languages?.map((language: string) => ({
          id: language,
          label: (findValue(language as string) as unknown) as string,
        }))
      );
      setValue("mainLanguage", [
        {
          id: project?.mainLanguage,
          label: (findValue(
            project?.mainLanguage as string
          ) as unknown) as string,
        },
      ]);
      setValue("metaTitle", project?.seoTagset?.metaTitle);
      setValue("metaDescription", project?.seoTagset?.metaDescription);
      setValue("ogTitle", project?.seoTagset?.ogTitle);
      setValue("ogDescription", project?.seoTagset?.ogDescription);
      setValue("metaKeywords", project?.seoTagset?.metaKeywords);
      setValue("canonical", project?.seoTagset?.canonical);
      !!project?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: project?.seoTagset?.robots,
            label: project?.seoTagset?.robots,
          },
        ]);
    }
  }, [data]);

  const watchLanguages = watch(ProjectsField.Languages);
  const watchMainLanguage = watch(ProjectsField.MainLanguage);

  useEffect(() => {
    if (
      (!(watchLanguages as SelectValue)?.some(
        (item) =>
          item.id === ((watchMainLanguage as SelectValue)?.[0]?.id as string)
      ) ||
        !(watchLanguages as SelectValue)?.length) &&
      JSON.stringify(
        (watchLanguages as SelectValue)?.map((item) => item.id)
      ) !== JSON.stringify(originalLanguages)
    )
      setValue("mainLanguage", []);
  }, [watchLanguages, data]);

  const [updateProject, { error }] = useMutation(PROJECTS_UPDATE);

  const onSubmit = async ({
    isActive,
    allowedDomains,
    languages,
    mainLanguage,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    ...values
  }: ProjectsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateProject({
        variables: {
          projectUpdateInput: {
            id: id ? parseInt(id) : null,
            allowedDomains: allowedDomains
              ? allowedDomains
                  ?.split(/\r?\n/)
                  .filter((line) => line.trim() !== "")
                  .join("\n")
                  .trim()
                  .split("\n")
              : [],
            isActive: !!isActive,
            languages: languages?.map(
              ({ id }: { id: string | number }) => id as string
            ),
            mainLanguage: mainLanguage?.[0]?.id,
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/projects/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.project.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={project?.name}
        labels={["Projekty", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateProject",
          },
        ]}
      />
      <Content>
        <FormProvider<ProjectsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<ProjectsFormInputs>
            id="updateProject"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={PROJECTS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji projektu"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
