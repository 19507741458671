import { gql, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Category } from "containers/Categories/categories";
import { CATEGORIES_SELECT } from "containers/Categories/categories.gql";
import { useAuth } from "contexts/auth-context";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { SelectWithActiveProjectProps } from "./select";

export default function ControlledCategoriesSelect({
  control,
  disabled,
  name,
  rules,
  activeProjectId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectWithActiveProjectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const { data, loading } = useQuery(CATEGORIES_SELECT, {
    variables: {
      ...(activeProjectId && {
        filter: {
          projectId: {
            eq: activeProjectId,
          },
        },
      }),
    },
  });

  const hasPermission = checkPermission(PERMISSIONS.category.read);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.categories?.nodes?.map(({ id, name }: Category) => ({
            id,
            label: name,
          }))}
          placeholder={
            hasPermission ? (
              "Wybierz"
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: " 5px",
                })}
              >
                <Ban size={16} />
                Brak uprawnień do przeglądania kategorii
              </div>
            )
          }
          disabled={disabled || !hasPermission}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          multi
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledParentCategorySelect({
  control,
  disabled,
  name,
  rules,
  activeProjectId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectWithActiveProjectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const CATEGORIES = gql`
    query($filter: CategoryFilter) {
      categories(paging: { limit: 10000 }, filter: $filter) {
        nodes {
          id
          name
        }
      }
    }
  `;

  const { data, loading } = useQuery(CATEGORIES, {
    variables: {
      filter: {
        and: [
          { parentId: { is: null } },
          { ...(activeProjectId && { projectId: { eq: activeProjectId } }) },
        ],
      },
    },
  });

  const hasPermission = checkPermission(PERMISSIONS.category.read);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            activeProjectId
              ? data?.categories?.nodes?.map(({ id, name }: Category) => ({
                  id,
                  label: name,
                }))
              : []
          }
          placeholder={
            hasPermission ? (
              "Wybierz"
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: " 5px",
                })}
              >
                <Ban size={16} />
                Brak uprawnień do przeglądania kategorii
              </div>
            )
          }
          disabled={disabled || !hasPermission}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}
