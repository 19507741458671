export enum FilterType {
  Activity = "Activity",
  ActivityLogsSubjectType = "activity-logs-subject-type",
  Article = "article",
  Boolean = "boolean",
  CreatedBy = "createdBy",
  DictionaryValues = "dictionary-values",
  CreatedBy = "created-by",
  Date = "date",
  Language = "language",
  Role = "role",
  SeoTagsetsSubjectType = "seo-tagsets-subject-type",
  Text = "text",
  UpdatedBy = "updatedBy",
  User = "user",
  Project = "project",
  UsersProject = "users-project",
  HeaderPosition = "header-position",
}

export type FilterState = {
  not?: boolean;
  [key: string]: any;
  dictionarySystemName?: string;
};

export type BasicFilter = {
  id: string;
  label: string;
  type: FILTERS;
  dictionarySystemName?: string;
};

export type SingleFilter = {
  uuid: string;
  filter: BasicFilter;
  state: FilterState;
};

export type FiltersState = SingleFilter[];
