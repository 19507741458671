import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { useProject } from "contexts/project-context";
import { BasicFilter, FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { Slide } from "../slides.d";
import { SLIDES_FILTERS } from "../slides.filters";
import { SlidesField } from "../slides.form";
import { SLIDES_INDEX, SLIDES_UPDATE } from "../slides.gql";

export default function SlidesIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<SlidesField | null>(
    SlidesField.Position
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();

  const { activeProject } = useProject();

  const handleSorting = (column: SlidesField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(SLIDES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(activeProject && {
              projectId: {
                eq: activeProject?.id,
              },
            }),
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });
  const slides: { totalCount: number; nodes: Slide[] } = data?.slides;

  useEffect(() => {
    if (data?.slides) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.slides) setIsFetching(false);
    if (slides?.totalCount >= 0) setTotalCount(slides?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [updateSlide] = useMutation(SLIDES_UPDATE);

  const onSubmit = async (slide: Slide): Promise<void> => {
    setIsLoading(true);

    try {
      await updateSlide({
        variables: {
          slideUpdateInput: {
            id: slide.id,
            isVisible: !slide.isVisible,
          },
        },
      });
      enqueueSnackbar({
        message: `Slajd "${slide.title}" jest ${
          slide.isVisible ? "niewidoczny" : "widoczny"
        }`,
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SlidesField.Title)}
            sortDirection={sortBy === SlidesField.Title ? sortDirection : null}
          >
            Tytuł
          </SortingTableHeader>
        ),
        accessor: SlidesField.Title,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue dataType={DataType.Slides} data={row.original.id}>
            {row.original.title}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Projekt",
        accessor: SlidesField.Project,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Projects}
            data={row.original?.project?.id}
            $style={{ fontSize: "12px" }}
          >
            {row.original?.project?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Wersja językowa",
        accessor: SlidesField.Language,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Language}
            data={row.original?.project?.id}
            $style={{ fontSize: "12px" }}
          >
            {row.original?.language}
          </FormattedValue>
        ),
      },
      {
        Header: "Pozycja",
        accessor: SlidesField.Position,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue>{row.original.position}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SlidesField.Url)}
            sortDirection={sortBy === SlidesField.Url ? sortDirection : null}
          >
            Adres strony WWW
          </SortingTableHeader>
        ),
        accessor: SlidesField.Url,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Link}
            $style={{ fontSize: "12px" }}
          >
            {row.original.url}
          </FormattedValue>
        ),
      },
      {
        Header: "Aktualność",
        accessor: SlidesField.Post,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Posts}
            data={row.original.post?.id}
          >
            {row.original.post?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Artykuł",
        accessor: SlidesField.Article,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Articles}
            data={row.original.article?.id}
          >
            {row.original.article?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Strona informacyjna",
        accessor: SlidesField.StaticPage,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.StaticPages}
            data={row.original.staticPage?.id}
          >
            {row.original.staticPage?.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SlidesField.CreatedAt)}
            sortDirection={
              sortBy === SlidesField.CreatedAt ? sortDirection : null
            }
          >
            Utworzenie
          </SortingTableHeader>
        ),
        accessor: SlidesField.CreatedAt,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Utworzono przez",
        accessor: SlidesField.CreatedBy,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.createdBy?.id}
          >
            {row.original?.createdBy
              ? renderUserLabel(row.original.createdBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SlidesField.UpdatedAt)}
            sortDirection={
              sortBy === SlidesField.UpdatedAt ? sortDirection : null
            }
          >
            Ostatnia aktualizacja
          </SortingTableHeader>
        ),
        accessor: SlidesField.UpdatedAt,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        accessor: SlidesField.UpdatedBy,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.updatedBy?.id}
          >
            {row.original?.updatedBy
              ? renderUserLabel(row.original.updatedBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SlidesField.IsVisible)}
            sortDirection={
              sortBy === SlidesField.IsVisible ? sortDirection : null
            }
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: SlidesField.IsVisible,
        Cell: ({ row }: { row: Row<Slide> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isVisible ? "niewidoczny" : "widoczny"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original)}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.isVisible}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Slide> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/slides/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.slide.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Slajdy"
        recordsNum={slides?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj slajd",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.slide.create),
            onClick: () => history.push("/slides/create"),
          },
        ]}
      />
      <Filters
        filters={SLIDES_FILTERS.filter((filter: BasicFilter) =>
          activeProject ? filter.id !== SlidesField.Project : filter
        )}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<Slide>
              columns={columns}
              data={slides?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
