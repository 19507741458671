import { DataType } from "components/formatted-value";
import { Role } from "containers/Roles/roles";

import { FieldsGroup, FieldType } from "../../fields.d";

export type UsersFormInputs = {
  firstName: string;
  lastName: string;
  description: string;
  email: string;
  roles: Role[];
  projects: { id: number; name: string }[];
  hasAllProjectsAccess: boolean;
};

export enum UsersField {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Description = "description",
  Status = "status",
  BlockedAt = "blockedAt",
  ActivatedAt = "activatedAt",
  LastLoginAt = "lastLoginAt",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  InvitedAt = "invitedAt",
  Roles = "roles",
  Permissions = "permissions",
  Projects = "projects",
  HasAllProjectsAccess = "hasAllProjectsAccess",
}

export const USERS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: UsersField.FirstName,
        label: "Imię",
        type: FieldType.BigInput,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        span: 6,
      },
      {
        id: UsersField.LastName,
        label: "Nazwisko",
        type: FieldType.BigInput,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        span: 6,
      },
      {
        id: UsersField.Email,
        label: "Adres e-mail",
        type: FieldType.Input,
        dataType: DataType.Email,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        span: 6,
      },
      {
        id: UsersField.Description,
        label: "Opis",
        type: FieldType.Input,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        placeholder: "Np. dział lub firma",
        span: 6,
      },
      {
        id: UsersField.Status,
        label: "Status",
        type: FieldType.UserStatus,
        dataType: DataType.UserStatus,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 6,
      },
    ],
  },
  {
    id: "roles",
    label: "Dostęp",
    fields: [
      {
        id: UsersField.Roles,
        label: "Role",
        type: FieldType.RolesMultiSelect,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
        span: 12,
      },
      {
        id: UsersField.Roles,
        label: "Role",
        type: FieldType.Multi,
        dataType: DataType.Roles,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 12,
      },
      {
        id: UsersField.HasAllProjectsAccess,
        label: "Dostęp do wszystkich projektów",
        type: FieldType.Switch,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
        caption:
          "Użytkownik z tą opcją będzie miał dostęp do wszystkich aktualnych i przyszłych projektów",
        span: 6,
      },
      {
        id: UsersField.Projects,
        label: "Projekty",
        type: FieldType.UsersProjects,
        dataType: DataType.Projects,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 12,
      },
      {
        id: UsersField.Projects,
        label: "Projekty",
        type: FieldType.UsersProjectSelect,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
        span: 12,
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: UsersField.CreatedAt,
        label: "Utworzenie",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 3,
      },
      {
        id: UsersField.InvitedAt,
        label: "Wysłanie zaproszenia",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 3,
      },
      {
        id: UsersField.ActivatedAt,
        label: "Aktywacja",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 3,
      },
      {
        id: UsersField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 3,
      },
      {
        id: UsersField.LastLoginAt,
        label: "Ostatnie logowanie",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 3,
      },
      {
        id: UsersField.BlockedAt,
        label: "Zablokowanie",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 3,
      },
    ],
  },
  {
    id: "permissions",
    label: "Uprawnienia",
    fields: [
      {
        id: UsersField.Permissions,
        label: "",
        type: FieldType.PermissionsTable,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
        span: 12,
      },
    ],
  },
];
