import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Project } from "containers/Projects/projects";

import { FieldsGroup, FieldType } from "../../fields.d";

export type FilesFormInputs = {
  project: Project[];
  language: SelectValue;
  name: string;
  isVisible: boolean;
  file: File[];
};

export enum FilesField {
  Language = "language",
  Project = "project",
  Name = "name",
  Filename = "filename",
  Mimetype = "mimetype",
  Size = "size",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  IsVisible = "isVisible",
  File = "file",
  Url = "url",
}

export const FILES_FIELDS: FieldsGroup[] = [
  {
    id: FilesField.Project,
    label: "",
    fields: [
      {
        id: FilesField.Project,
        label: "Projekt",
        span: 6,
        type: FieldType.ProjectsSelect,
        dataType: DataType.Projects,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: FilesField.Language,
        label: "Wersja językowa",
        span: 6,
        type: FieldType.ProjectLanguagesSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Lista zawiera języki przypisane do wybranego projektu",
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: FilesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        autocomplete: "name",
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: FilesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        readonly: true,
        span: 6,
        show: { visible: true, span: 12 },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: FilesField.Filename,
        label: "Nazwa pliku",
        type: FieldType.FileDownload,
        dataType: DataType.FileDownload,
        span: 6,
        autocomplete: "name",
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: FilesField.Filename,
        label: "Rozszerzenie",
        type: FieldType.Input,
        dataType: DataType.FileFormat,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: FilesField.Size,
        label: "Rozmiar",
        type: FieldType.Input,
        dataType: DataType.FileSize,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "file",
    label: "",
    fields: [
      {
        id: FilesField.File,
        label: "Plik",
        type: FieldType.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Maksymalnie 8 MB",
        maxSize: 8388608,
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: FilesField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: FilesField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: FilesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: FilesField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
