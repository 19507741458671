import { BasicFilter, FilterType } from "./../../filters.d";
import { ProjectsField } from "./projects.form";

export const PROJECTS_FILTERS: BasicFilter[] = [
  {
    id: ProjectsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: ProjectsField.IsActive,
    label: "Aktywny",
    type: FilterType.Boolean,
  },
  {
    id: ProjectsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: ProjectsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: ProjectsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: ProjectsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
];
