import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Project } from "containers/Projects/projects";

import { FieldsGroup, FieldType } from "../../fields.d";

export type TagsFormInputs = {
  project: Project[];
  language: SelectValue;
  name: string;
  slug: string;
  isVisible?: boolean;
};

export enum TagsField {
  Language = "language",
  Project = "project",
  Name = "name",
  Slug = "slug",
  IsVisible = "isVisible",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedBy",
}

export const TAGS_FIELDS: FieldsGroup[] = [
  {
    id: TagsField.Project,
    label: "",
    fields: [
      {
        id: TagsField.Project,
        label: "Projekt",
        span: 6,
        type: FieldType.ProjectsSelect,
        dataType: DataType.Projects,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: TagsField.Language,
        label: "Wersja językowa",
        span: 6,
        type: FieldType.ProjectLanguagesSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Lista zawiera języki przypisane do wybranego projektu",
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: TagsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: TagsField.Slug,
        label: "Slug",
        span: 12,
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: TagsField.IsVisible,
        label: "Widoczność",
        span: 6,
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: TagsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: TagsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: TagsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: TagsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
