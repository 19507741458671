import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { LabelMedium } from "baseui/typography";
import { ActionsList } from "components/actions-list";
import { Cell } from "components/cell";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Api, Key, Pencil, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Project } from "../projects";
import { PROJECTS_FIELDS } from "../projects.form";
import { PROJECTS_DELETE, PROJECTS_SHOW } from "../projects.gql";

export default function ProjectsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(PROJECTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    notifyOnNetworkStatusChange: true,
  });
  const project: Project = data?.project;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.project) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.project && setIsFetching(false), [data]);

  const [deleteProject] = useMutation(PROJECTS_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteProject({
        variables: {
          projectDeleteInput: { id: id ? parseInt(id) : null },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/projects");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.project.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={project?.name}
        labels={["Projekty", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.project.update),
            onClick: () => history.push(`/projects/${id}/update`),
          },
        ]}
        actions={[
          {
            label: "Usuń projekt",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.project.delete),
          },
        ]}
      />
      <Content>
        {data && (
          <ObjectViewRenderer<Project>
            fields={PROJECTS_FIELDS}
            data={project}
            refetch={refetch}
          />
        )}
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          label={`Usunięcie projektu ${project?.name}`}
          close={() => setIsConfirmDialogOpen(false)}
          confirm={submit}
        />

        <Grid>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Przydatne linki
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Playground GraphQL",
                  icon: Api,
                  onClick: () =>
                    window.open(process.env.REACT_APP_GRAPHQL_PUBLIC_API_URL),
                },
                {
                  label: (
                    <span>
                      Klucz API:{" "}
                      <FormattedValue dataType={DataType.Pre}>
                        {data?.project?.apiToken}
                      </FormattedValue>{" "}
                    </span>
                  ),
                  icon: Key,
                  onClick: () => {
                    navigator.clipboard.writeText(data?.project?.apiToken);
                    enqueueSnackbar({
                      message: "Skopiowano do schowka",
                      variant: "success",
                    });
                  },
                },
              ]}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
