export const FORM_VALIDATION_MESSAGES = {
  differentPasswords: "Podane nowe hasła są różne",
  incorrectEmail: "Adres e-mail jest nieprawidłowy",
  maxLength: "Przekroczono dozwoloną liczbę znaków",
  required: "To pole jest wymagane",
  requiredPermission: "Należy zaznaczyć przynajmniej jedno uprawnienie",
  unsavedPhoto: "Nie zapisano zdjęcia",
  requiredUsersProject:
    "Należy wybrać projekt lub przyznać dostęp do wszystkich",
};
