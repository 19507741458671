import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type ProjectsFormInputs = {
  name: string;
  allowedDomains: string;
  isActive: boolean;
  languages: SelectValue;
  mainLanguage: SelectValue;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
};

export enum ProjectsField {
  Name = "name",
  ApiToken = "apiToken",
  AllowedDomains = "allowedDomains",
  Languages = "languages",
  MainLanguage = "mainLanguage",
  IsActive = "isActive",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  SeoTagset = "seoTagset",
}

export const PROJECTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: ProjectsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        autocomplete: "title",
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Maksymalnie 30 znaków",
        maxLength: 30,
      },
      {
        id: ProjectsField.AllowedDomains,
        label: "Dozwolone domeny",
        span: 6,
        type: FieldType.TextArea,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ProjectsField.AllowedDomains,
        label: "Dozwolone domeny",
        span: 6,
        type: FieldType.Domains,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProjectsField.IsActive,
        label: "Aktywny",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: "apiToken",
        label: "Klucz API",
        span: 12,
        type: FieldType.Token,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "languages",
    label: "Wielojęzyczność",
    fields: [
      {
        id: "languages",
        label: "Wersje językowe",
        span: 6,
        type: FieldType.LanguageSelect,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: "languages",
        label: "Wersje językowe",
        span: 6,
        type: FieldType.Languages,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: "mainLanguage",
        label: "Główna wersja językowa",
        span: 6,
        type: FieldType.MainLanguageSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "ProjectUsers",
    label: "Użytkownicy przypisani do projektu",
    fields: [
      {
        id: "projectUsers",
        label: "",
        span: 12,
        type: FieldType.ProjectUsersTable,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.MetaDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 200 znaków ze spacjami",
        maxLength: 200,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ProjectsField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: ProjectsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProjectsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProjectsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProjectsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
