import { useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { Project } from "containers/Projects/projects";
import { PROJECTS_SELECT } from "containers/Projects/projects.gql";
import { UsersField } from "containers/Users/users.form";
import { useAuth } from "contexts/auth-context";
import { useProject } from "contexts/project-context";
import React, { ReactElement } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import stc from "string-to-color";
import { Ban, World } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { SelectProps } from "./select";

export default function ProjectsSelect({
  disabled,
  showAll,
  ...rest
}: SelectProps & { showAll?: boolean }): ReactElement {
  const [css] = useStyletron();

  const { myProjects } = useProject();
  const { data, loading } = useQuery(PROJECTS_SELECT);
  const projects = showAll ? data?.projects?.nodes : myProjects;

  return (
    <Select
      options={[
        ...(showAll
          ? [
              {
                id: UsersField.HasAllProjectsAccess,
                label: "Dostęp do wszystkich",
              },
            ]
          : []),
        ...(projects?.length
          ? projects?.map(({ id, name }: Project) => ({
              id,
              label: name,
            }))
          : []),
      ]}
      placeholder="Wybierz"
      disabled={disabled}
      isLoading={loading}
      getValueLabel={({ option }) => (
        <Block display="flex" alignItems="center">
          {option?.id === UsersField.HasAllProjectsAccess ? (
            <World size={16} />
          ) : (
            <div
              className={css({
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: stc(option?.label),
                flexShrink: 0,
              })}
            />
          )}

          <LabelSmall $style={{ marginLeft: "10px" }}>
            {option?.label}
          </LabelSmall>
        </Block>
      )}
      getOptionLabel={({ option }) => (
        <Block display="flex" alignItems="center" position="relative">
          {option?.id === UsersField.HasAllProjectsAccess ? (
            <World size={12} />
          ) : (
            <div
              className={css({
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: stc(option?.label),
                flexShrink: 0,
              })}
            />
          )}

          <LabelSmall $style={{ marginLeft: "8px" }}>
            {option?.label}
          </LabelSmall>
        </Block>
      )}
      {...rest}
    />
  );
}

export function ControlledProjectsSelect({
  control,
  disabled,
  name,
  rules,
  multi,
  showAll,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  SelectProps & { showAll?: boolean }): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const { myProjects } = useProject();
  const { data, loading } = useQuery(PROJECTS_SELECT);
  const projects = showAll ? data?.projects?.nodes : myProjects;

  const hasPermission = checkPermission(PERMISSIONS.project.read);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={projects?.map(({ id, name, languages }: Project) => ({
            id,
            label: name,
            languages,
          }))}
          placeholder={
            hasPermission ? (
              "Wybierz"
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: " 5px",
                })}
              >
                <Ban size={16} />
                Brak uprawnień do przeglądania projektów
              </div>
            )
          }
          disabled={disabled || !hasPermission}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...(myProjects && { value: value })}
          getValueLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              {option?.id === 0 ? (
                <World size={16} />
              ) : (
                <div
                  className={css({
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    backgroundColor: stc(option?.label),
                    flexShrink: 0,
                  })}
                />
              )}

              <LabelSmall
                $style={{
                  marginLeft: "10px",
                  ...(multi && { color: "white" }),
                }}
              >
                {option?.label}
              </LabelSmall>
            </Block>
          )}
          getOptionLabel={({ option }) => (
            <Block display="flex" alignItems="center" position="relative">
              {option?.id === 0 ? (
                <World size={12} />
              ) : (
                <div
                  className={css({
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: stc(option?.label),
                    flexShrink: 0,
                  })}
                />
              )}

              <LabelSmall $style={{ marginLeft: "8px" }}>
                {option?.label}
              </LabelSmall>
            </Block>
          )}
          multi={multi}
          {...rest}
        />
      )}
    />
  );
}
