import { useLazyQuery, useQuery } from "@apollo/client";
import { ConfirmDialog } from "components/confirm-dialog";
import { NAV } from "components/side-nav/side-nav";
import { Project } from "containers/Projects/projects";
import { MY_PROJECTS, PROJECTS_SHOW } from "containers/Projects/projects.gql";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FormType } from "../constants";

type ProjectContextProps = {
  isFetchingProject: boolean;
  activeProjectId?: number;
  activeProject?: Project;
  setActiveProjectId: (projectId: number) => void;
  myProjects?: Project[];
  isContentModuleOpen?: boolean;
  changeActiveProjectId: (projectId: number) => void;
};

export const ProjectContext = createContext<ProjectContextProps>(
  {} as ProjectContextProps
);

export function ProjectProvider({
  children,
  activeProjectId: activeProjectIdFromProps,
}: {
  children: React.ReactNode;
  activeProjectId?: number;
}): React.ReactElement {
  const [activeProjectId, setActiveProjectId] = useState<number | undefined>(
    activeProjectIdFromProps
  );

  const [activeProject, setActiveProject] = useState<Project | undefined>();
  const [choosedProjectId, setChoosedProjectId] = useState<
    number | undefined
  >();
  const [isFetchingProject, setIsFetchingProject] = useState<boolean>(
    activeProjectIdFromProps ? true : false
  );
  const [myProjects, setMyProjects] = useState<Project[]>();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const isContentModuleOpen = NAV.find((item) => item.id === "content")
    ?.items?.map((item) => item.href)
    .some((item) => history.location.pathname.includes(item));

  const [fetchProject, { data, refetch, loading }] = useLazyQuery(
    PROJECTS_SHOW,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const {
    data: myProjectsData,
    refetch: refetchMyProjects,
  } = useQuery(MY_PROJECTS, { variables: { pageSize: 50 } });

  const changeActiveProjectId = (projectId: number) => {
    if (
      isContentModuleOpen &&
      history.location.pathname.includes(FormType.Update) &&
      projectId !== 0
    ) {
      setIsConfirmDialogOpen(true);
      setChoosedProjectId(projectId);
      return;
    }

    if (
      isContentModuleOpen &&
      !history.location.pathname.includes(FormType.Create) &&
      history.location.pathname.split("/").length === 3 &&
      projectId !== 0
    ) {
      history.push(`/${history.location.pathname.split("/")[1]}`);
    }

    setActiveProjectId(projectId as number);
  };

  useEffect(() => {
    if (!isConfirmDialogOpen) setChoosedProjectId(undefined);
  }, [isConfirmDialogOpen]);

  useEffect(() => {
    if (myProjectsData) refetchMyProjects();
  }, []);

  useEffect(() => {
    setMyProjects(myProjectsData?.myProjects?.nodes);
  }, [myProjectsData]);

  useEffect(() => {
    if (activeProjectId) {
      setIsFetchingProject(true);

      if (data?.project.id === activeProjectId) {
        refetch && refetch();
      } else {
        fetchProject({ variables: { id: activeProjectId } });
      }
    } else {
      setActiveProject(undefined);
    }

    if (activeProjectId !== undefined && typeof window !== "undefined") {
      localStorage.setItem(
        "gcms:active-project-id",
        activeProjectId.toString()
      );
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (data) {
      setActiveProject(data.project);
      setIsFetchingProject(false);

      enqueueSnackbar({
        message: `Edytujesz treści w projekcie ${data.project.name}`,
        variant: "info",
        preventDuplicate: true,
      });
    }
  }, [data, loading]);

  useEffect(() => {
    if (activeProjectId === 0) {
      setIsFetchingProject(false);
    }
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        isFetchingProject,
        activeProjectId,
        activeProject,
        setActiveProjectId,
        myProjects,
        isContentModuleOpen,
        changeActiveProjectId,
      }}
    >
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        label="Zmiana projektu spowoduje zamknięcie formularza edycji."
        close={() => setIsConfirmDialogOpen(false)}
        confirm={() => {
          history.push(`/${history.location.pathname.split("/")[1]}`);
          setIsConfirmDialogOpen(false);
          setActiveProjectId(choosedProjectId);
        }}
      />
      {children}
    </ProjectContext.Provider>
  );
}

export const useProject = (): ProjectContextProps => useContext(ProjectContext);
