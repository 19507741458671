import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useDictionaries } from "contexts/dictionaries-context";
import { useLoading } from "contexts/loading-context";
import { useProject } from "contexts/project-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { CustomSetting } from "../custom-settings";
import {
  CUSTOM_SETTINGS_FIELDS,
  CustomSettingsFormInputs,
} from "../custom-settings.form";
import {
  CUSTOM_SETTINGS_SHOW,
  CUSTOM_SETTINGS_UPDATE,
} from "../custom-settings.gql";

export default function CustomSettingsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { activeProject, activeProjectId } = useProject();
  const { findValue } = useDictionaries();
  const [originalProjectId, setOriginalProjectId] = useState<number>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<CustomSettingsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(CUSTOM_SETTINGS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const customSetting: CustomSetting = data?.customSetting;

  useEffect(() => {
    setIsFetching(true);
    if (data?.customSetting) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (customSetting) {
      setIsFetching(false);

      setOriginalProjectId(customSetting?.project?.id);

      setValue("project", [
        { ...customSetting?.project, id: customSetting?.project?.id },
      ]);
      setValue("language", [
        {
          id: customSetting?.language,
          label: (findValue(
            customSetting?.language as string
          ) as unknown) as string,
        },
      ]);
      setValue("name", customSetting?.name);
      setValue("systemName", customSetting?.systemName);
      setValue("value", customSetting?.value);
    }
  }, [data]);

  useEffect(() => {
    !activeProject &&
      setValue("project", [
        { ...customSetting?.project, id: customSetting?.project?.id },
      ]);
  }, [activeProject]);

  const watchProject = watch("project");

  useEffect(() => {
    if (
      originalProjectId === watchProject?.[0]?.id ||
      originalProjectId === activeProjectId
    )
      setValue("language", [
        {
          id: customSetting?.language,
          label: (findValue(
            customSetting?.language as string
          ) as unknown) as string,
        },
      ]);
    else setValue("language", []);
  }, [watchProject, activeProject]);

  useEffect(() => {
    setIsFetching(true);
    if (data?.customSetting) refetch();
  }, []);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, CUSTOM_SETTINGS_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateCustomSetting, { error }] = useMutation(CUSTOM_SETTINGS_UPDATE);

  const onSubmit = async ({
    project,
    language,
    ...values
  }: CustomSettingsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateCustomSetting({
        variables: {
          customSettingUpdateInput: {
            id: id ? parseInt(id) : null,
            projectId: activeProjectId || project?.[0]?.id,
            language: language?.[0]?.id,
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/custom-settings/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.customSetting.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={customSetting?.name}
        labels={["Inne treści", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateCustomSetting",
          },
        ]}
      />
      <Content>
        <FormProvider<CustomSettingsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<CustomSettingsFormInputs>
            id="updateCustomSetting"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={CUSTOM_SETTINGS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
