import { BasicFilter, FilterType } from "../../filters.d";
import { CustomSettingsField } from "./custom-settings.form";

export const CUSTOM_SETTINGS_FILTERS: BasicFilter[] = [
  {
    id: CustomSettingsField.Project,
    label: "Projekt",
    type: FilterType.Project,
  },
  {
    id: CustomSettingsField.Language,
    label: "Wersja językowa",
    type: FilterType.Language,
  },
  {
    id: CustomSettingsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: CustomSettingsField.SystemName,
    label: "Nazwa systemowa",
    type: FilterType.Text,
  },
  {
    id: CustomSettingsField.Value,
    label: "Wartość",
    type: FilterType.Text,
  },
  {
    id: CustomSettingsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: CustomSettingsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: CustomSettingsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: CustomSettingsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
];
