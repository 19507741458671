import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { Button } from "components/button";
import { Modal } from "components/modal";
import { TextArea } from "components/text-area";
import { Project } from "containers/Projects/projects";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React from "react";

type IntegrationInstructionModalProps = {
  isOpen: boolean;
  close: () => void;
  project: Project;
};

export default function IntegrationInstructionModal({
  isOpen,
  close,
  project,
}: IntegrationInstructionModalProps): React.ReactElement {
  const { isLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const textContent = `Podstawowe dane:
Nazwa projektu: ${project?.name}
Klucz API: ${project?.apiToken}
Endpoint API: ${process.env.REACT_APP_GRAPHQL_PUBLIC_API_URL?.replace(
    "https://",
    ""
  )}
Playground API: ${process.env.REACT_APP_GRAPHQL_PUBLIC_API_URL}

Przykładowy sposób użycia:
curl -X POST\\
${process.env.REACT_APP_GRAPHQL_PUBLIC_API_URL}\\
-H 'Content-Type: application/json'\\
-H 'Authorization: Bearer ${project?.apiToken}'\\
-d '{"query": "{ currentProject { name } }"}'

Przydatne linki:
Oficjalna strona GraphQL: https://graphql.org/

Wsparcie techniczne:
support-globalcms@maspex.com`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(textContent);

    enqueueSnackbar({
      variant: "success",
      message: "Skopiowano do schowka",
    });
  };

  return (
    <Modal
      onClose={close}
      closeable={!isLoading}
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
      $style={{ width: "600px" }}
    >
      <ModalHeader>Instrukcja integracji z Maspex Global CMS</ModalHeader>

      <ModalBody>
        <TextArea value={textContent} $style={{ height: "400px" }} />
      </ModalBody>

      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
          disabled={isLoading}
        >
          Anuluj
        </Button>

        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleCopyToClipboard}
        >
          Kopiuj
        </Button>
      </ModalFooter>
    </Modal>
  );
}
