import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Button } from "components/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Input } from "components/input";
import { IntegrationInstructionModal } from "components/modal";
import { Tooltip } from "components/tooltip";
import { Project } from "containers/Projects/projects";
import { PROJECTS_REGENERATE_TOKEN } from "containers/Projects/projects.gql";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { Article, Copy, Refresh } from "tabler-icons-react";

type TokenRegeneratorProps = {
  refetch?: () => void;
  project: Project;
};

export default function TokenRegenerator({
  refetch,
  project,
}: TokenRegeneratorProps): ReactElement {
  const { isLoading, setIsLoading } = useLoading();
  const [css, theme] = useStyletron();

  const [inputValue, setInputValue] = useState(project?.apiToken);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [
    isIntegrationInstructionModalOpen,
    setIsIntegrationInstructionModalOpen,
  ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();

  const [regenerateToken, { error }] = useMutation(PROJECTS_REGENERATE_TOKEN);

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await regenerateToken({
        variables: {
          id: id && parseInt(id),
        },
      });

      enqueueSnackbar({
        message: "Nowy klucz API został wygenerowany",
        variant: "success",
      });

      setInputValue(data?.projectRegenerateApiToken?.apiToken);
      refetch && refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inputValue as string);

    enqueueSnackbar({
      variant: "success",
      message: "Skopiowano do schowka",
    });
  };

  return (
    <div className={css({ display: "flex", gap: "12px" })}>
      <div className={css({ flexGrow: 1 })}>
        <Input
          id="token"
          disabled={isLoading}
          value={inputValue}
          size="mini"
          name="apiToken"
          endEnhancer={
            <Tooltip content="Kopiuj" placement="auto">
              <span
                onClick={handleCopyToClipboard}
                className={css({
                  cursor: "pointer",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: theme.colors.borderFocus,
                  transition: ".1s",
                  ":hover": {
                    color: theme.colors.inputPlaceholder,
                  },
                })}
              >
                <Copy size={18} />
              </span>
            </Tooltip>
          }
        />
      </div>

      <Button
        size="mini"
        kind="secondary"
        onClick={() => setIsConfirmDialogOpen(true)}
        startEnhancer={<Refresh size={16} />}
        isLoading={isLoading}
      >
        Wygeneruj ponownie
      </Button>

      <Button
        size="mini"
        kind="secondary"
        onClick={() => setIsIntegrationInstructionModalOpen(true)}
        startEnhancer={<Article size={16} />}
        isLoading={isLoading}
      >
        Pobierz instrukcję integracji
      </Button>

      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        label={"Czy na pewno chcesz wygenerować nowy klucz API?"}
        close={() => setIsConfirmDialogOpen(false)}
        confirm={() => {
          onSubmit();
          setIsConfirmDialogOpen(false);
        }}
      />

      <IntegrationInstructionModal
        isOpen={isIntegrationInstructionModalOpen}
        close={() => setIsIntegrationInstructionModalOpen(false)}
        project={project}
      />
    </div>
  );
}
