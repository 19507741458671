import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { LoadingBar } from "components/loading-bar";
import { LOGOUT } from "containers/Auth/auth.gql";
import { ACCOUNT_UPDATE } from "containers/MyAccount/my-account.gql";
import { User } from "containers/Users/users";
import { useLoading } from "contexts/loading-context";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";
import { client } from "utils/client";

import { AUTH_TOKEN } from "../constants";

type AuthContextProps = {
  user: User | null;
  setUser: (user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (authenticated: boolean) => void;
  refetch: () => void;
  checkPermission: (componentPermission: string) => boolean;
  interfacePreferences: JSON | undefined;
  getInterfacePreferences: () => JSON | undefined;
  updateUser: (user: Partial<User>) => void;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

const FETCH_USER = gql`
  query Me {
    me {
      id
      firstName
      lastName
      email
      description
      blockedAt
      activatedAt
      lastLoginAt
      deletedAt
      createdAt
      updatedAt
      interfacePreferences
      permissions {
        id
        systemName
      }
      roles {
        id
        name
      }
      projects {
        id
        name
      }
      hasAllProjectsAccess
    }
  }
`;

export function AuthProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: AuthContextProps;
}): React.ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [
    isFetchingAuthenticatedUser,
    setIsFetchingAuthenticatedUser,
  ] = useState(true);

  const { setIsFetching } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const token: string | null = localStorage.getItem(AUTH_TOKEN);

  const { refetch } = useQuery(FETCH_USER, {
    onCompleted({ me }) {
      setUser(me);
      setIsAuthenticated(true);
      setIsFetchingAuthenticatedUser(false);
      setIsFetching(false);
    },
    onError() {
      localStorage.removeItem(AUTH_TOKEN);
      setIsFetchingAuthenticatedUser(false);
      setIsFetching(false);
    },
    notifyOnNetworkStatusChange: true,
    skip: !token,
  });

  useEffect(() => {
    if (token) setIsFetching(true);
    else setIsFetchingAuthenticatedUser(false);
  }, []);

  const [logoutUser] = useMutation(LOGOUT);

  const logoutSubmit = async (): Promise<void> => {
    try {
      await logoutUser();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    }
  };

  const [updateUser] = useMutation(ACCOUNT_UPDATE);

  const updateUserSubmit = (user: Partial<User>): void => {
    updateUser({ variables: { accountUpdateInput: user } });

    setUser((user_) => user_ && { ...user_, ...user });
  };

  if (isFetchingAuthenticatedUser) {
    return <LoadingBar />;
  }

  function logout() {
    localStorage.removeItem(AUTH_TOKEN);
    client.cache.reset();
    logoutSubmit();
    setUser(null);
    setIsAuthenticated(false);

    enqueueSnackbar({
      message: "Wylogowano pomyślnie",
      variant: "success",
    });
  }

  function checkPermission(componentPermission: string) {
    return (
      !!user &&
      !!user.permissions &&
      user.permissions.some(
        (permission) => permission.systemName === componentPermission
      )
    );
  }

  function getInterfacePreferences() {
    return user?.interfacePreferences as JSON;
  }

  return (
    <AuthContext.Provider
      value={
        value || {
          user,
          setUser,
          logout,
          isAuthenticated,
          setIsAuthenticated,
          refetch,
          checkPermission,
          interfacePreferences: user?.interfacePreferences as JSON,
          getInterfacePreferences,
          updateUser: updateUserSubmit,
        }
      }
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => useContext(AuthContext);
