import { gql } from "@apollo/client";

export const FILES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: FileFilter
    $sorting: [FileSort!]
  ) {
    files(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        filename
        mimetype
        size
        url
        project {
          id
          name
        }
        language
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          email
        }
        isVisible
      }
    }
  }
`;

export const FILES_SHOW = gql`
  query FileShow($id: Int!) {
    file(id: $id) {
      id
      name
      filename
      mimetype
      size
      url
      project {
        id
        name
      }
      language
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        email
      }
      isVisible
    }
  }
`;

export const FILES_CREATE = gql`
  mutation FileCreate($fileCreateInput: FileCreateInput!) {
    fileCreate(fileCreateInput: $fileCreateInput) {
      id
    }
  }
`;

export const FILES_UPDATE = gql`
  mutation FileUpdate($fileUpdateInput: FileUpdateInput!) {
    fileUpdate(fileUpdateInput: $fileUpdateInput) {
      id
    }
  }
`;

export const FILES_DELETE = gql`
  mutation FileDelete($fileDeleteInput: FileDeleteInput!) {
    fileDelete(fileDeleteInput: $fileDeleteInput) {
      id
    }
  }
`;
