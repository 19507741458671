import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";

import { DictionarySystemName, Typename } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type SeoTagsetsFormInputs = {
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
};

export enum SeoTagsetsField {
  Id = "id",
  SubjectType = "subjectType",
  Subject = "subject",
  Slug = "slug",
  MetaTitle = "metaTitle",
  MetaDescription = "metaDescription",
  MetaKeywords = "metaKeywords",
  OgTitle = "ogTitle",
  OgDescription = "ogDescription",
  Canonical = "canonical",
  Robots = "robots",
}

export const SEO_TAGSETS_FIELDS: FieldsGroup[] = [
  {
    id: "object",
    label: "Obiekt",
    fields: [
      {
        id: SeoTagsetsField.Subject,
        label: "Nazwa",
        type: FieldType.SeoTagsetSubject,
        typeName: Typename.Article,
        dataType: DataType.Articles,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.Subject,
        label: "Nazwa",
        type: FieldType.SeoTagsetSubject,
        typeName: Typename.Project,
        dataType: DataType.Projects,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.Subject,
        label: "Nazwa",
        type: FieldType.SeoTagsetSubject,
        typeName: Typename.Post,
        dataType: DataType.Posts,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.Subject,
        label: "Nazwa",
        type: FieldType.SeoTagsetSubject,
        typeName: Typename.StaticPage,
        dataType: DataType.StaticPages,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.SubjectType,
        label: "Typ",
        span: 6,
        type: FieldType.Input,
        dataType: DataType.Subject,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.Slug,
        label: "Slug",
        span: 6,
        type: FieldType.Input,
        dataType: DataType.Pre,
        show: { visible: true, accessor: ["subject", "slug"] },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 200 znaków ze spacjami",
        maxLength: 200,
        span: 6,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
];
