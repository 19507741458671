import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { DisplayXSmall } from "baseui/typography";
import { ActionsList } from "components/actions-list";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Grid } from "components/grid";
import { useLoading } from "contexts/loading-context";
import React, { useEffect } from "react";
import { Calendar, FileImport, Help } from "tabler-icons-react";

export default function Home(): React.ReactElement {
  const [css] = useStyletron();
  const { setIsFetching } = useLoading();

  useEffect(() => {
    setIsFetching(false);
  }, []);

  return (
    <article>
      <Content>
        <Grid>
          <Cell span={12}>
            <Calendar size={90} />
            <Block
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <DisplayXSmall>Twój dashboard</DisplayXSmall>
              <Button
                kind="secondary"
                $as="a"
                // href="/knowledge-base"
                // onClick={(event: MouseEvent) => {
                //   event.preventDefault();
                //   history.push("/knowledge-base");
                // }}
                startEnhancer={() => <Help size={20} />}
                $style={{ marginBottom: "4px" }}
              >
                Baza wiedzy
              </Button>
            </Block>
          </Cell>
          <Cell span={12}>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Przykładowa akcja 1",
                  icon: FileImport,
                },
                {
                  label: "Przykładowa akcja 2",
                  icon: FileImport,
                },
              ]}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
