import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useDictionaries } from "contexts/dictionaries-context";
import { useLoading } from "contexts/loading-context";
import { useProject } from "contexts/project-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { StaticPage } from "../static-pages";
import {
  STATIC_PAGES_FIELDS,
  StaticPagesFormInputs,
} from "../static-pages.form";
import { STATIC_PAGES_SHOW, STATIC_PAGES_UPDATE } from "../static-pages.gql";

export default function StaticPagesUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { activeProject, activeProjectId } = useProject();
  const { findValue } = useDictionaries();
  const [originalProjectId, setOriginalProjectId] = useState<number>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<StaticPagesFormInputs>();

  const { refetch, data, error: queryError } = useQuery(STATIC_PAGES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const staticPage: StaticPage = data?.staticPage;

  useEffect(() => {
    setIsFetching(true);
    if (data?.staticPage) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (staticPage) {
      setOriginalProjectId(staticPage?.project?.id);

      setValue("project", [
        { ...staticPage?.project, id: staticPage?.project?.id },
      ]);
      setValue("language", [{ id: staticPage?.language }]);
      setValue("name", staticPage?.name);
      setValue("redirectUrl", staticPage?.redirectUrl);
      setValue("content", staticPage?.content);
      setValue("isVisible", staticPage?.isVisible);
      setValue("slug", staticPage?.slug);
      setValue("metaTitle", staticPage?.seoTagset?.metaTitle);
      setValue("metaDescription", staticPage?.seoTagset?.metaDescription);
      setValue("ogTitle", staticPage?.seoTagset?.ogTitle);
      setValue("ogDescription", staticPage?.seoTagset?.ogDescription);
      setValue("metaKeywords", staticPage?.seoTagset?.metaKeywords);
      setValue("canonical", staticPage?.seoTagset?.canonical);
      setValue("showInHeader", staticPage?.showInHeader);
      setValue("headerPosition", staticPage?.headerPosition);
      !!staticPage?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: staticPage?.seoTagset?.robots,
            label: staticPage?.seoTagset?.robots,
          },
        ]);

      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    !activeProject &&
      setValue("project", [
        { ...staticPage?.project, id: staticPage?.project?.id },
      ]);
  }, [activeProject]);

  const watchProject = watch("project");

  useEffect(() => {
    if (
      originalProjectId === watchProject?.[0]?.id ||
      originalProjectId === activeProjectId
    )
      setValue("language", [
        {
          id: staticPage?.language,
          label: (findValue(
            staticPage?.language as string
          ) as unknown) as string,
        },
      ]);
    else setValue("language", []);
  }, [watchProject, activeProject]);

  useEffect(() => {
    setIsFetching(true);
    if (data?.staticPage) refetch();
  }, []);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, STATIC_PAGES_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateStaticPage, { error }] = useMutation(STATIC_PAGES_UPDATE);

  const onSubmit = async ({
    project,
    language,
    isVisible,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    ...values
  }: StaticPagesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateStaticPage({
        variables: {
          staticPageUpdateInput: {
            id: id ? parseInt(id) : null,
            projectId: activeProjectId || project?.[0]?.id,
            language: language?.[0]?.id,
            isVisible: !!isVisible,
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/static-pages/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.staticPage.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={staticPage?.name}
        labels={["Strony informacyjne", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateStaticPage",
          },
        ]}
      />
      <Content>
        <FormProvider<StaticPagesFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<StaticPagesFormInputs>
            id="updateStaticPage"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={STATIC_PAGES_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji strony informacyjnej"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
