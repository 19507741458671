import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Project } from "containers/Projects/projects";

import { FieldsGroup, FieldType } from "../../fields.d";

export type CustomSettingsFormInputs = {
  language: SelectValue;
  project: Project[];
  name: string;
  systemName: string;
  value: string;
};

export enum CustomSettingsField {
  Language = "language",
  Project = "project",
  Name = "name",
  SystemName = "systemName",
  Value = "value",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
}

export const CUSTOM_SETTINGS_FIELDS: FieldsGroup[] = [
  {
    id: CustomSettingsField.Project,
    label: "",
    fields: [
      {
        id: CustomSettingsField.Project,
        label: "Projekt",
        span: 6,
        type: FieldType.ProjectsSelect,
        dataType: DataType.Projects,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: CustomSettingsField.Language,
        label: "Wersja językowa",
        span: 6,
        type: FieldType.ProjectLanguagesSelect,
        dataType: DataType.Language,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption: "Lista zawiera języki przypisane do wybranego projektu",
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: CustomSettingsField.Name,
        label: "Nazwa",
        type: FieldType.Input,
        autocomplete: "none",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: CustomSettingsField.SystemName,
        label: "Nazwa systemowa",
        type: FieldType.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: CustomSettingsField.Value,
        label: "Wartość",
        type: FieldType.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: CustomSettingsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: CustomSettingsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: CustomSettingsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: CustomSettingsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
