import { DataType } from "components/formatted-value";

import { Typename } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export enum ActivityLogsField {
  Id = "id",
  Activity = "activity",
  CreatedAt = "createdAt",
  User = "user",
  Description = "description",
  Subject = "subject",
  Object = "object",
  Properties = "properties",
  IpAddress = "ipAddress",
  OperatingSystem = "operatingSystem",
  BrowserName = "browserName",
  BrowserVersion = "browserVersion",
  Project = "project",
}

export const ACTIVITY_LOGS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: ActivityLogsField.User,
        label: "Użytkownik",
        type: FieldType.Input,
        dataType: DataType.Users,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.CreatedAt,
        label: "Data",
        type: FieldType.Input,
        dataType: DataType.DateTime,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "details",
    label: "Szczegóły akcji",
    fields: [
      {
        id: ActivityLogsField.Description,
        label: "Akcja",
        type: FieldType.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Subject,
        label: "Przedmiot aktywności",
        type: FieldType.Input,
        span: 3,
        show: { accessor: ["subject", "__typename"], visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.User,
        type: FieldType.Input,
        dataType: DataType.Users,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Dictionary,
        type: FieldType.Input,
        dataType: DataType.Dictionaries,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.DictionaryValue,
        type: FieldType.Input,
        dataType: DataType.Dictionaries,
        span: 3,
        show: { accessor: ["subject", "dictionaryId"], visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Role,
        type: FieldType.Input,
        dataType: DataType.Roles,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Tag,
        type: FieldType.Input,
        dataType: DataType.Tags,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Article,
        type: FieldType.Input,
        dataType: DataType.Articles,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.StaticPage,
        type: FieldType.Input,
        dataType: DataType.StaticPages,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Category,
        type: FieldType.Input,
        dataType: DataType.Categories,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.CustomSetting,
        type: FieldType.Input,
        dataType: DataType.CustomSettings,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Tag SEO",
        typeName: Typename.SeoTagset,
        type: FieldType.Input,
        dataType: DataType.SeoTagsets,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Slide,
        type: FieldType.Input,
        dataType: DataType.Slides,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Image,
        type: FieldType.Input,
        dataType: DataType.Images,
        span: 3,
        show: { visible: true, accessor: ["subject", "originalName"] },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.File,
        type: FieldType.Input,
        dataType: DataType.Files,
        span: 3,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Project,
        type: FieldType.Input,
        dataType: DataType.Projects,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Object,
        label: "Obiekt",
        typeName: Typename.Post,
        type: FieldType.Input,
        dataType: DataType.Posts,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.Project,
        label: "Projekt",
        type: FieldType.Input,
        dataType: DataType.Projects,
        span: 3,
        show: { visible: true, accessor: ["project", "name"] },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "properties",
    label: "Właściwości",
    accessor: "properties",
    fields: [
      {
        id: ActivityLogsField.IpAddress,
        label: "Adres IP",
        type: FieldType.Input,
        dataType: DataType.Pre,
        info: ActivityLogsField.Properties,
        span: 12,
        show: {
          accessor: [ActivityLogsField.Properties, ActivityLogsField.IpAddress],
          visible: true,
        },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.OperatingSystem,
        label: "System operacyjny",
        type: FieldType.Input,
        info: ActivityLogsField.Properties,
        span: 3,
        show: {
          accessor: [
            ActivityLogsField.Properties,
            ActivityLogsField.OperatingSystem,
          ],
          visible: true,
        },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.BrowserName,
        label: "Przeglądarka",
        type: FieldType.Input,
        info: ActivityLogsField.Properties,
        span: 3,
        show: {
          accessor: [
            ActivityLogsField.Properties,
            ActivityLogsField.BrowserName,
          ],
          visible: true,
        },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ActivityLogsField.BrowserVersion,
        label: "Wersja przeglądarki",
        type: FieldType.Input,
        info: ActivityLogsField.Properties,
        span: 3,
        show: {
          accessor: [
            ActivityLogsField.Properties,
            ActivityLogsField.BrowserVersion,
          ],
          visible: true,
        },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
